import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import DropdownIndicator from './DropdownIndicator';
import { useTranslation } from 'react-i18next';

const TimeInput = ({ value = null, step, onChange, maxHour = 24, minHour = 0, isClearable = false, disabled = false, placeholder, classNameInput = '' }) => {
  const { t } = useTranslation();

  const times = [];
  const startRange = minHour * 60;
  const endRange = (maxHour + 0.25) * 60;
  for (let i = startRange; i < endRange; i += step) {
    const hh = Math.floor(i / 60);
    const mm = i % 60;
    times.push(`${hh.toString().padStart(2, '0')}:${mm.toString().padStart(2, '0')}`);
  }

  const [selectedTime, setSelectedTime] = useState(null);

  const handleTimeChange = (time) => {
    const timeValue = time?.value;
    setSelectedTime(time);
    onChange(timeValue);
  };

  useEffect(() => {
    setSelectedTime(value ? { label: value, value: value } : null);
  }, [value]);

  return (step ?
    <div className='flex gap-1'>
      <Select
        components={{ DropdownIndicator }}
        className={`react-custom-select flex-grow ${classNameInput}`}
        classNamePrefix="react-select"
        placeholder={placeholder || t('utils.common.start')}
        options={times.map((time) => (
          { label: time, value: time }
        ))}
        value={selectedTime}
        onChange={handleTimeChange}        
        isClearable={isClearable}
        isDisabled={disabled}
      />
    </div>
    : <div>
      <input type="time" value={selectedTime} disabled={disabled} placeholder={placeholder || t('utils.common.time')} onChange={handleTimeChange} />
    </div>
  );
};

export default TimeInput;