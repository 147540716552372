import { getRequest, putRequest } from '../../api/methodsCfg';
import { getLatLongFromAddress } from '../../utils/gis';

export const getAllCompanyForms = async () => {
    const response = await getRequest('/companyform/all');
    return response;
};

export const getCompanyForm = async (id) => {
    const response = await getRequest(`/companyform/${id}`);
    return response;
};

export const confirmCompany = async (form) => {
    let x, y = null;
    try {
        const { latitude, longitude } = await getLatLongFromAddress(form.address);
        x = latitude;
        y = longitude;
    } catch (error) {
        console.error('Error while fetching lat long:', error);
    }
    const payload = {
        company: {
            name: form.companyName,
            address: form.address,
            cityId: form.cityId,
            email: form.email,
            phone: form.phone,
            x,
            y,
        },
        user: {
            firstname: form.firstName,
            lastname: form.lastName,
            username: form.email,
            email: form.email,
            phone: form.phone,
            languageId: form.languageId,
        },
    };
    const response = await putRequest(`/companyform/confirm/${form.companyFormsId}`, payload);
    return response;
};

export const declineCompany = async (formId) => {
    const response = await putRequest(`/companyform/decline/${formId}`);
    return response;
};

export const getAllCompanies = async () => {
    const response = await getRequest('/company/all');
    return response;
};

export const getCompany = async (id) => {
    const response = await getRequest(`/company/${id}`);
    return response;
};