import axios from 'axios';
import { getEnvVariable } from '../utils/config';

export const getLatLongFromAddress = async (address, npa = '', city = '') => {
    const fullAddress = `${address} ${npa} ${city}`
    try {
        const coordinateResponse = await axios.get(`https://geocode.maps.co/search?q=${fullAddress}&api_key=${getEnvVariable('REACT_APP_MAPS_API_KEY')}`);
        if(!coordinateResponse.data.length) return { latitude: 0, longitude: 0 };
        const latitude = coordinateResponse.data[0].lat;
        const longitude = coordinateResponse.data[0].lon;
        return { latitude, longitude};
    } catch (error) {
        console.error(error);
    }
};