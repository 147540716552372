function Button({ children, className, size = 'md', variant = 'primary', icon = false, rounded = false, block = false, outlined = false, link = false, disabled = false, onClick }) {
    let buttonClass = link ? `btn-link ${block ? 'btn-block' : ''}`
        : `btn-${variant}${outlined ? '-outlined' : ''} btn-${size}  ${rounded ? 'btn-rounded' : ''} ${icon ? 'btn-icon' : ''} ${block ? 'btn-block' : ''}`;
    buttonClass += ` ${className}`;
    return <button className={`flex align-center ${buttonClass}`} onClick={onClick} disabled={disabled}>
        {children}
    </button>;
}

export default Button;
