import React from 'react';
import Tag from './Tag'
function TagList({ tags, tKey = 'utils.foodtags.' }) {
    return (
        <div className='tag-list'>
            {tags.map((tag) => (
                <Tag tag={tag.label ? tag : null} text={tag} tKey={tKey} key={tag} />
            ))}
        </div>
    );
}

export default TagList;