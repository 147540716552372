import { createSlice } from "@reduxjs/toolkit";
import { setRestaurantId, restoreRestaurantId, setCompanyId, restoreCompanyId } from "./userSlice";

const initialState = {
    controlledRestaurantId: null,
    controlledRestaurantName: null,
    controlledCompanyId: null,
    controlledCompanyName: null,
    isControllingEntity: false,
};

const adminSlice = createSlice({
    name: "admin",
    initialState,
    reducers: {
        setControlledRestaurant: (state, action) => {
            state.controlledRestaurantId = action.payload.restaurantId;
            state.controlledRestaurantName = action.payload.name;
            state.isControllingEntity = true;
        },
        setControlledCompany: (state, action) => {
            state.controlledCompanyId = action.payload.companyId;
            state.controlledCompanyName = action.payload.name;
            state.isControllingEntity = true;        
        },
        clearControlledRestaurant: () => initialState,
        clearControlledCompany: () => initialState,
    },
});

export const { setControlledRestaurant, clearControlledRestaurant, setControlledCompany, clearControlledCompany } = adminSlice.actions;

export const startControllingRestaurant = (restaurant) => (dispatch) => {
    dispatch(setControlledRestaurant(restaurant));
    dispatch(setRestaurantId(restaurant.restaurantId));
};

export const stopControllingRestaurant = () => (dispatch) => {
    dispatch(clearControlledRestaurant());
    dispatch(restoreRestaurantId());
};

export const startControllingCompany = (company) => (dispatch) => {
    dispatch(setControlledCompany(company));
    dispatch(setCompanyId(company.companyId));
};

export const stopControllingCompany = () => (dispatch) => {
    dispatch(clearControlledCompany());
    dispatch(restoreCompanyId());
};

export default adminSlice.reducer;