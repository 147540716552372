import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import useApi from "../../../hooks/useApi";
import { getAllCompanyForms, confirmCompany, declineCompany } from "../../../services/admin/companyService";
import Table from '../../table/Table'
import NoData from '../../errors/NoData';
import DecoratedInput from '../../inputs/DecoratedInput';
import DropdownButton from "../../buttons/DropdownButton";
import { formatPhoneNumber } from "../../../utils/formatting";

export const AdminCompanyForms = ({ tKey = 'dashboard.admin.companyForm.' }) => {
  usePageTitle('adminCompanyForms');
  const { t } = useTranslation();
  const [companyForms, setCompanyForms] = useState([]);
  const [filtered, setFilteredForms] = useState([]);
  const [query, setQuery] = useState('');
  const {
    request: getCompanyForms,
  } = useApi(getAllCompanyForms);
  const {
    request: confirmCompanyRequest,
  } = useApi(confirmCompany);
  const {
    request: declineCompanyRequest,
  } = useApi(declineCompany);
  
  const handleFormDelete = useCallback((formId) => {
    declineCompanyRequest(formId).then(() => {
      setCompanyForms(companyForms.filter((form) => form.companyFormsId !== formId));
    }).catch((error) => {
      console.log(error);
    });
  }, [declineCompanyRequest, companyForms, setCompanyForms]);

  const handleFormDetail = useCallback((form) => {
    console.log('Form detail:', form);
  }, []);

  const getFormActions = useCallback((form) => {
    const actions = [
      { label: t(`${tKey}actions.view`), onClick: () => handleFormDetail(form) },        
    ]
    if (!form.companyId) {
      actions.push({ label: t(`${tKey}actions.confirm`), onClick: () => confirmCompanyRequest(form) });
      actions.push({ label: t(`${tKey}actions.decline`), onClick: () => handleFormDelete(form.companyFormsId) });
    }
    return actions;
  }, [t, tKey, confirmCompanyRequest, handleFormDetail, handleFormDelete]);

  const columns = useMemo(() => [
    {
      Header: t(`${tKey}name`),
      accessor: 'companyName',
    },
    {
      Header: t(`${tKey}userName`),
      accessor: (row) => `${row.firstName} ${row.lastName}`,
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}message`),
      accessor: 'message',
    },
    {
      Header: t(`${tKey}address`),
      accessor: 'address',
    },
    {
      Header: t(`${tKey}city`),
      accessor: (row) => `${row.city.npa} ${row.city.label}`,
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}contact`),
      accessor: (row) => <>
        <div><a href={`mailto:${row.email}?subject=Votre demande sur Offood`}>{ row.email }</a></div>
        <div>{ formatPhoneNumber(row.phone) }</div>
      </>,
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={getFormActions(row.original)}
        >
          <FontAwesomeIcon color='black' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [t, tKey, getFormActions]);

  useEffect(() => {
    const fetchFormsData = async () => {
      await getCompanyForms().then((response) => {
        setCompanyForms(response.data);
      }).catch((error) => {
        console.log(error);
      });
    };    
    fetchFormsData();
  }, [getCompanyForms]);

  const filterCompanyForms = useCallback((forms) => {
    if (query) return forms.filter((form) => form.name.toLowerCase().includes(query.toLowerCase()) || form.firstName.toLowerCase().includes(query.toLowerCase()) || form.lastName.toLowerCase().includes(query.toLowerCase()) || form.address.toLowerCase().includes(query.toLowerCase()) || form.message.toLowerCase().includes(query.toLowerCase()) );
    return forms;
  }, [query]);

  useEffect(() => {
    setFilteredForms(filterCompanyForms(companyForms));
  }, [companyForms, query, filterCompanyForms]);

  return (
    <div className="dashboard-content">
      <div className="flex gap-1">
        <DecoratedInput className='bg-white pl-2 dashboard-search-bar flex-grow' input={{ id: 'searchForms', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: companyForms ? companyForms.length : 0 }) }}
          hasResearchButton
          onResearchButtonClick={ (query) => { setQuery(query) } }
        />        
      </div>
      <div className='table-container mt-4'>
        {filtered?.length === 0 ? <NoData />
          : <Table rowClassName='p-4' columns={columns} data={filtered} onClickRow={handleFormDetail} />}    
      </div>
    </div>
  );
};
  
export default AdminCompanyForms;