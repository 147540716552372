import { getRequest, postRequest } from '../api/methodsCfg';

export const getInvoice = async (invoiceId) => {
    const response = await getRequest(`/invoice/${invoiceId}`);
    return response;
};

export const getRestaurantInvoices = async (restaurantId, from = null, to = null) => {
    const response = await postRequest(`/invoice/restaurant/${restaurantId}`, {
        from, to
    });
    return response;
};