import { useEffect, useState } from 'react';
import { connectWebSocket, subscribeToNotifications, onMessage, closeWebSocket } from '../services/webSocketService';
import { wsServicesMapping } from '../utils/constants';
import { getEnvVariable } from '../utils/config';

const useWebSocket = (service, userId) => {
    const [notifications, setNotifications] = useState(0);
    const url = `${getEnvVariable('REACT_APP_WS_BASE_URL')}${wsServicesMapping[service]}`;
    useEffect(() => {
        connectWebSocket(url);
        subscribeToNotifications(userId);
        const handleMessage = (message) => {
            if (message.action === 'new_notification') setNotifications((prev) => prev + 1);
            else if (message.action === 'unviewed_notifications') setNotifications(message.notifications.length);
        };
        onMessage(handleMessage);
        return () => {
            closeWebSocket();
        };
    }, [url, userId]);
    
    return { setNotifications, notifications };
};

export default useWebSocket;
