import React from 'react';
import { useTranslation } from 'react-i18next';

const EmptyTableMessage = ({ colSpan = 1 }) => {
    const { t } = useTranslation();
    return (<tr>
        <td colSpan={colSpan}>
            <div className='text-center w-100'>
                <p className='text-light italic'>{t('utils.errors.noData.title')}</p>
            </div>
        </td>
    </tr>);
};

export default EmptyTableMessage;
