import React, { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useApi from '../../hooks/useApi';
import { postForgotPassword } from '../../services/authService';
import Button from '../../components/buttons/Button';
import Form from '../../components/inputs/Form';
import useLangNavigate from '../../hooks/useLangNavigate';

function ForgotPassword({ tKey = 'forgotPassword.'}) {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const [fields, setFields] = useState([])
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const {        
        request: forgotPasswordRequest,
    } = useApi(postForgotPassword);

    useMemo(() => {
        setFields([            
            {
                name: 'email',
                label: t(`${tKey}email.label`),
                placeholder: t(`${tKey}email.placeholder`),
                type: 'email',
                class: 'w-100',
                validate: (value) => {
                    if (!value) return t(`${tKey}errors.email.required`);
                    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return t(`${tKey}errors.email.invalid`);
                    return '';
                },
            },
        ]);
    }, [t, tKey]);

    async function attemptForgotPassword() {
        await forgotPasswordRequest(formData.email)
            .then(() => {                
                toast.success(t(`${tKey}success`));
                navigate('login');
            })
            .catch(() => {
                toast.error(t(`${tKey}error`));
            });
    };

    return (
        <div id='login'>
            <div className='login-illustration' />
            <div className='login-content'>
                <h4>{t(`${tKey}title`)}</h4>
                <div className='p1 login-form'>
                    <Form fields={fields} currentForm={formData} setFormDetails={setFormData} setIsValid={setIsValid} />
                    <Button variant='primary' disabled={!isValid} onClick={attemptForgotPassword}>
                        {t(`${tKey}submit`)}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ForgotPassword;