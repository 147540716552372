import React, { useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useApi from '../../hooks/useApi';
import { postChangeForgotPassword } from '../../services/authService';
import Button from '../../components/buttons/Button';
import Form from '../../components/inputs/Form';
import useLangNavigate from '../../hooks/useLangNavigate';

function ChangeForgotPassword({ tKey = 'forgotPassword.'}) {
    const { t } = useTranslation();
    const navigate = useLangNavigate();
    const forgotPasswordToken = useParams().forgotPasswordToken;
    const username = useParams().username;
    const [fields, setFields] = useState([])
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const {
        request: changeForgotPasswordRequest,
    } = useApi(postChangeForgotPassword);

    useMemo(() => {
        setFields([            
            {
                name: 'password',
                label: t(`${tKey}password.label`),
                placeholder: t(`${tKey}password.placeholder`),
                type: 'password',
                class: 'w-100',
                info: t(`${tKey}password.info`),
                validate: (value) => {
                    if (!value) return t(`${tKey}errors.password.required`);
                    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(value)) return t('register.errors.password.invalid');
                    return '';
                },
            },
            {
                name: 'confirmPassword',
                label: t(`${tKey}confirmPassword.label`),
                placeholder: t(`${tKey}confirmPassword.placeholder`),
                type: 'password',
                class: 'w-100',
                validate: (value, form) => {
                    if (!value) return t(`${tKey}errors.confirmPassword.required`);
                    if (!form || (value !== form.password)) return t(`${tKey}errors.confirmPassword.match`);
                    return '';
                },
            },
        ]);
    }, [t, tKey]);

    async function attemptChangeForgotPassword() {
        await changeForgotPasswordRequest({ ...formData, username, forgotPasswordToken })
            .then(() => {                
                toast.success(t(`${tKey}changeSuccess`));
                navigate('login');
            })
            .catch(() => {
                toast.error(t(`${tKey}error`));
            });
    };

    return (
        <div id='login'>
            <div className='login-illustration' />
            <div className='login-content'>
                <h4>{t(`${tKey}titleChange`)}</h4>
                <div className='p1 login-form'>
                    <Form fields={fields} currentForm={formData} setFormDetails={setFormData} setIsValid={setIsValid} />
                    <Button variant='primary' disabled={!isValid} onClick={attemptChangeForgotPassword}>
                        {t(`${tKey}submitChange`)}
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ChangeForgotPassword;