import { useState } from 'react';

export const useDishCreationModal = () => {
  const [isDishModalOpen, setIsDishModalOpen] = useState(false);
  const [dishFormData, setDishFormData] = useState({});
  const [isDishValid, setIsDishValid] = useState(false);

  const openDishModal = () => {
    setIsDishModalOpen(true);
    setDishFormData({});
    setIsDishValid(false);
  };

  const closeDishModal = () => setIsDishModalOpen(false);

  return {
    isDishModalOpen,
    openDishModal,
    closeDishModal,
    dishFormData,
    setDishFormData,
    isDishValid,
    setIsDishValid,
  };
};