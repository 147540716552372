import { getRequest, putRequest, deleteRequest } from '../api/methodsCfg';
import  { getLatLongFromAddress } from '../utils/gis';

export const getCompanySettings = async (companyId) => {
    const response = await getRequest(`/company/${companyId}`);
    return response;
}

export const updateCompanySettings = async (companyId, payload) => {
    let x, y = null;
    try {        
        const { latitude, longitude } = await getLatLongFromAddress(payload.address, payload.city?.value?.npa, payload.city?.value?.label);
        x = latitude;
        y = longitude;
    } catch (error) {
        throw error;
    }
    const cleanPayload = {
        ...payload, 
        city: payload.city.value ? payload.city.value.cityId : payload.cityId,
        x,
        y,
    };
    const response = await putRequest(`/company/${companyId}/${payload.name}`, cleanPayload, true);
    return response;
};

export const getCompanyPartners = async (companyId) => {
    const response = await getRequest(`/company/partners/${companyId}`);
    return response;
};

export const getAvailableOffers = async (companyId) => {
    const response = await getRequest(`/company/availableOffers/${companyId}`);
    return response;
};

export const putTransferMangerRole = async (companyId, payload) => {
    const response = await putRequest(`/company/transferManagerRole/${companyId}`, payload);
    return response;
};

export const subscribeToOffer = async (companyId, offerId) => {
    const response = await putRequest(`/company/subscribe/${companyId}`, { offerId });
    return response;
};

export const unsubscribeToOffer = async (companyId, offerId) => {
    const response = await deleteRequest(`/company/unsubscribe/${companyId}`, { offerId });
    return response;
};

export const deleteCompany = async (companyId) => {
    const response = await deleteRequest(`/company/${companyId}`);
    return response;
};