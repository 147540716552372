import { createContext, useState } from 'react';

const SettingsContext = createContext({
  activeTab: '',
  isValid: false,
  hideSettingsButton: false,
  setActiveTab: () => {},
  setIsValid: () => {},
  setHideSettingsButton: () => {},
  applyChanges: () => {},
  setApplyChanges: () => {},
});

export const SettingsProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [applyChanges, setApplyChanges] = useState(() => {});
  const [hideSettingsButton, setHideSettingsButton] = useState(false);

  return (
    <SettingsContext.Provider value={{ activeTab, setActiveTab, isValid, setIsValid, applyChanges, setApplyChanges, setHideSettingsButton, hideSettingsButton }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsContext;