import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { usePageTitle } from '../../../hooks/useMeta';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import useModal from '../../../hooks/useModal';
import useApi from '../../../hooks/useApi';
import Button from '../../buttons/Button';
import DecoratedInput from '../../inputs/DecoratedInput';
import Spinner from '../../utils/Spinner';
import NoData from '../../errors/NoData';
import Table from '../../table/Table';
import Modal from '../../utils/Modal';
import UserAvatar from '../../utils/UserAvatar';
import Tag from '../../utils/Tag';
import Form from '../../inputs/Form';
import { getRestaurantUsers, getRestaurantUserDetail, createNewRestaurantEmployee, updateRestaurantEmployee, closeRestaurantEmployeeAccount } from '../../../services/userService';
import { formatPhoneNumber } from '../../../utils/formatting';

export const RestaurantEmployees = ({ tKey = 'dashboard.employees.' }) => {
  usePageTitle('restaurantEmployees');
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getEmployeesError,
    loading: getEmployeesLoading,
    request: getEmployeesRequest,
  } = useApi(getRestaurantUsers);
  const {
    request: getEmployeeDetail,
  } = useApi(getRestaurantUserDetail);
  const {
    request: createEmployeeRequest,
  } = useApi(createNewRestaurantEmployee);
  const {
    request: updateEmployeeRequest,
  } = useApi(updateRestaurantEmployee);
  const {
    request: deleteEmployeeRequest,
  } = useApi(closeRestaurantEmployeeAccount);
  const [query, setQuery] = useState('');
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [newEmployee, setNewEmployee] = useState({});
  const [isValid, setIsValid] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  
  const fetchRestaurantEmployees = useCallback(async (restaurantId) => {
    try {
      const response = await getEmployeesRequest(restaurantId);
      setEmployees(response.data);
    } catch (error) {
      console.error('Failed to fetch restaurant employees:', error.message);
    }
  }, [getEmployeesRequest, setEmployees]);
  
  const closeAndResetModal = useCallback(() => {
    closeModal();
    setModalContent(null);
    setSelectedEmployee(null);
  }, [closeModal, setModalContent, setSelectedEmployee]);

  const deleteSelectedEmployee = useCallback(async (employee) => {
    try {
      await deleteEmployeeRequest(employee.userId);
      toast.success(t(`${tKey}delete.success`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } catch (error) {
      console.error(error);
      toast.error(t(`${tKey}delete.error`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } finally {
      closeAndResetModal();
      await fetchRestaurantEmployees(restaurantId);
    }
  }, [deleteEmployeeRequest, t, tKey, closeAndResetModal, fetchRestaurantEmployees, restaurantId]);
  
  const attemptEditEmployee = useCallback(async () => {
    try {
      await updateEmployeeRequest(selectedEmployee.userId, selectedEmployee);
      toast.success(`${tKey}edit.success`);
    } catch (error) {
      console.error(error);
      toast.error(`${tKey}edit.error`);
    } finally {
      closeAndResetModal();
      await fetchRestaurantEmployees(restaurantId);
    }
  }, [selectedEmployee, updateEmployeeRequest, tKey, closeAndResetModal, fetchRestaurantEmployees, restaurantId]);

  const fields = useMemo(() => [
    {
      name: 'userId',
      type: 'hidden',
    },
    {
      name: 'lastname',
      placeholder: t(`${tKey}lastname.placeholder`),
      type: 'text',
      class: 'w-100',
      validate: (value) => {
        if(!value) return t(`${tKey}errors.lastname.required`);
        if(value.length < 2) return t(`${tKey}errors.lastname.min`);
        if(value.length > 25) return t(`${tKey}errors.lastname.max`);
        return '';
      },
    },
    {
      name: 'firstname',
      placeholder: t(`${tKey}firstname.placeholder`),
      type: 'text',
      class: 'w-100',
      validate: (value) => {
        if(!value) return t(`${tKey}errors.firstname.required`);
        if(value.length < 2) return t(`${tKey}errors.firstname.min`);
        if(value.length > 25) return t(`${tKey}errors.firstname.max`);
        return '';
      },
    },                
    {
      name: 'email',
      placeholder: t(`${tKey}email.placeholder`),
      type: 'email',
      class: 'w-100',
      validate: (value) => {
        if (!value) return t(`${tKey}errors.email.required`);
        if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)) return t(`${tKey}errors.email.invalid`);
        return '';
      },
    }, 
    {
      name: 'phone',
      placeholder: t(`${tKey}phone.placeholder`),
      type: 'phone',
      class: 'w-100',
      validate: (value) => {
        if (!value) return t(`${tKey}errors.phone.required`);
        if (!isValidPhoneNumber(value)) return t(`${tKey}errors.phone.invalid`);
        return '';
      },
    },
    {
      name: 'occupiedFunction',
      placeholder: t(`${tKey}function.placeholder`),
      type: 'text',
      class: 'w-100',
      validate: (value) => {
        if (!value.trim()) return t(`${tKey}errors.function.required`);
        if (value.length > 50) return t(`${tKey}errors.function.max`);
        return '';
      },      
    },
  ], [t, tKey]);

  const handleEdit = useCallback((employee) => async () => {
    try {
      const response = await getEmployeeDetail(employee.userId);
      setSelectedEmployee(response.data);
      const employeeData = response.data;
      setModalContent(
        <>
          <div className="flex justify-between gap-2 mb-3">
            <h3 className="my-0">
              {employeeData.firstname} {employeeData.lastname}
            </h3>
            <div>
              <Tag
                variant={employeeData.isValidated ? 'success' : 'danger'}
                tKey={tKey}
                text={employeeData.isValidated ? 'isValidated' : 'notValidated'}
              />
            </div>
          </div>
          <div>
            <Form fields={fields} currentForm={employeeData} setFormDetails={setSelectedEmployee} setIsValid={setIsValid} />
          </div>
          <div className="modal-footer">
            <Button variant="secondary" onClick={closeAndResetModal}>
              {t('utils.common.close')}
            </Button>
            <Button variant="info" onClick={() => attemptEditEmployee(employeeData)}>
              {t('utils.common.edit')}
            </Button>
          </div>
        </>
      );
      openModal();
    } catch (error) {
      console.error(error);
    }
  }, [getEmployeeDetail, t, tKey, fields, setSelectedEmployee, setIsValid, attemptEditEmployee, openModal, closeAndResetModal]);

  const handleDelete = useCallback((employee) => () => {
    setSelectedEmployee(employee);
    setModalContent(<>
      <h3 className='my-0'>{employee.firstname} {employee.lastname}</h3>
      <p>{t(`${tKey}delete.text`, { fullname: `${employee.firstname} ${employee.lastname}` })}</p>
      <div className='modal-footer'>
        <Button variant='secondary' onClick={closeAndResetModal}>{t('utils.common.close')}</Button>
        <Button variant='danger' onClick={() => deleteSelectedEmployee(employee)}>{t('utils.common.confirmDelete')}</Button>
      </div>
    </>);
    openModal();
  }, [t, tKey, closeAndResetModal, deleteSelectedEmployee, openModal, setSelectedEmployee]);

  const columns = useMemo(() => [
    {
      accessor: 'imagePath',
      Cell: ({ row }) => {
        return <UserAvatar src={row.original.imagePath} size='50' />;
      },
      disableSortBy: true,
    },
    {
      accessor: 'firstname',
    },
    {
      accessor: 'lastname',
    },
    {
      accessor: 'occupiedFunction',
    },
    {
      id: 'actions',
      Cell: ({ row }) => (<div className='flex gap-1'>
        <Button variant='info' size='sm' onClick={(e) => {
          e.stopPropagation();
          handleEdit(row.original)();
        }}>
          {t(`${tKey}edit.btn`)}
        </Button>
        <Button variant='danger' size='sm' onClick={(e) => {
          e.stopPropagation();
          handleDelete(row.original)();
        }}>
          {t(`${tKey}delete.btn`)}
        </Button>        
      </div>),
      disableSortBy: true,
    },
  ], [handleDelete, handleEdit, t, tKey]);

  const clearForm = () => {
    setNewEmployee({});
    setIsValid(false);
  };

  const handleDetail = async (row) => {
    try {
      const response = await getEmployeeDetail(row.original.userId);
      setSelectedEmployee(response.data);
      const employeeData = response.data;
      setModalContent(
        <div className="flex align-center gap-3">
          <div>
            <UserAvatar src={employeeData.imagePath} size="150" />
          </div>
          <div className="flex-grow">
            <div className="flex justify-between mb-4">
              <h5 className="my-0">
                {employeeData.firstname} {employeeData.lastname}
              </h5>
              <div>
                <Tag
                  variant={employeeData.isValidated ? 'success' : 'danger'}
                  tKey={tKey}
                  text={employeeData.isValidated ? 'isValidated' : 'notValidated'}
                />
              </div>
            </div>
            <div>
              <p className="my-1">{employeeData.email}</p>
              <p className="my-1">{formatPhoneNumber(employeeData.phone)}</p>
              <p className="my-1">{employeeData.occupiedFunction}</p>
            </div>
          </div>
        </div>
      );
      openModal();
    } catch (error) {
      console.error(error);
    }
  };

  const attemptCreateEmployee = async () => {
    try {
      await createEmployeeRequest(newEmployee, restaurantId);
      toast.success(`${tKey}create.success`);
    } catch (error) {
      console.error(error);
      toast.error(`${tKey}create.error`);
    } finally {
      clearForm();
      await fetchRestaurantEmployees(restaurantId);
    }
  };

  const filterEmployees = useCallback((query) => {
    if (query)
      return employees.filter((employee) => {
        return employee.firstname.toLowerCase().includes(query.toLowerCase()) ||
          employee.lastname.toLowerCase().includes(query.toLowerCase());
      });
    return employees;
  }, [employees]);

  useEffect(() => {
    fetchRestaurantEmployees(restaurantId);
  }, [restaurantId, fetchRestaurantEmployees]);

  useEffect(() => {
    setFilteredEmployees(filterEmployees(query));
  }, [query, employees, filterEmployees]);

  if (getEmployeesError) return <div>Error: {getEmployeesError}</div>;
  if (getEmployeesLoading) return <Spinner />;

  return (<>
    <div className='dashboard-content w-100 flex gap-2'>
      <div className='w-50 bg-white p-3 rounded flex column gap-3'>
        <p className='m-0 p1'>{t(`${tKey}tableHeader`)}</p>
        <DecoratedInput className='bg-pastel-light pl-2 dashboard-search-bar w-100' input={{ id: 'searchEmployee', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: employees ? employees.length : 0 }) }}
          hasResearchButton
          onResearchButtonClick={ (query) => { setQuery(query) } }
        />
        {filteredEmployees?.length === 0 ? <NoData />
          : <Table noHeader rowClassName='p-4' columns={columns} data={filteredEmployees} onClickRow={handleDetail} />}    
      </div>    
      <div className='w-50 bg-white p-3 rounded flex column gap-3'>
        <p className='m-0 p1'>{t(`${tKey}addEmployee`)}</p>
        <Form currentForm={newEmployee} fields={fields} setFormDetails={setNewEmployee} setIsValid={setIsValid} />
        <div>
          <Button onClick={attemptCreateEmployee} disabled={!isValid}>
            {t(`${tKey}confirm`)}
          </Button>
        </div>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeAndResetModal} noFooter>
      {modalContent}
    </Modal>
  </>);
};

export default RestaurantEmployees;