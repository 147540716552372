import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useLangNavigate from '../../hooks/useLangNavigate';
import { setUser } from '../../redux/slices/userSlice';
import Button from '../../components/buttons/Button';
import Alert from '../../components/utils/Alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/pro-light-svg-icons';
import { useTranslation } from 'react-i18next';
import useApi from '../../hooks/useApi';
import { useAuth } from '../../hooks/useAuth';
import { postAttemptLogin } from '../../services/authService';
import { withAuthenticatedRoute } from '../../components/HOC/RoutingLogic';
import { usePageTitle } from '../../hooks/useMeta';
import OTPInput from "react-otp-input";

function Login() {
  usePageTitle('login');

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFACode, setTwoFACode] = useState(null);
  const { t } = useTranslation();
  const navigate = useLangNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();

  const {
    error: attemptLoginError,
    status: attemptLoginStatus,
    request: attemptLoginRequest,
  } = useApi(postAttemptLogin);

  const goToHome = () => {
    navigate('/');
  };

  useEffect(() => {
    if (isAuthenticated)
      navigate(`dashboard`);
  }, [isAuthenticated, navigate]);

  const attemptLogin = async () => { 
    await attemptLoginRequest({ email, password, twoFACode }).then((response) => {
        // Dispatch the setUser action with the user data
        dispatch(setUser(response.data));
        // Save the token in localStorage
        localStorage.setItem('token', response.data.token);
        navigate(`dashboard`);
    }).catch((error) => {
      console.error("Error during login:", error);
    })
  };

  return <div id='login'>
    <div className='login-illustration' />
    <div className='login-content'>
      <Button variant='tertiary' onClick={goToHome}>
        <FontAwesomeIcon size='xl' icon={faArrowLeftLong} />
      </Button>
      <h1>{t('login.title')}</h1>
      <p className='p1 flex gap-1'>
        <span>{t('login.noAccount')}</span>
        <Button className='true-link' link onClick={() => navigate('register')}>
          <span className='p1'>{t('login.register')}</span>
        </Button>
      </p>
      {(attemptLoginError && attemptLoginStatus !== 428) && <Alert variant='danger'>{attemptLoginError}</Alert>}
      <div className='p1 login-form'>
        <div>
          <label htmlFor='email'>{t('login.form.email.label')}</label>
          <input id='email' type='text' placeholder={t('login.form.email.label')} 
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <label htmlFor='password'>{t('login.form.password.label')}</label>
          <input id='password' type='password' placeholder={t('login.form.password.label')} 
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        {(attemptLoginStatus === 428 || attemptLoginStatus === 403) && 
        <div>
          <label htmlFor='twoFACode'>{t('login.form.twoFACode.label')}</label>
          <OTPInput value={twoFACode}
            numInputs={6} 
            separator={<span></span>} 
            onChange={setTwoFACode} 
            renderInput={(props) => <input {...props} />} 
            inputStyle={'otp-input'}
            containerStyle={'otp-container'}
          />
        </div>}
        <Button className='true-link' link onClick={() => navigate('forgotPassword')}>
          <span className='p1'>{t('login.form.forgotPassword')}</span>
        </Button>
        <Button size='md' onClick={attemptLogin}>
          <span className='p1'>{t('login.form.next')}</span>
        </Button>
      </div>
    </div>
  </div>;
}

export default withAuthenticatedRoute(Login);
