// src/hooks/useLangNavigate.js
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useLangNavigate = () => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const navigateWithLang  = useCallback(
        (to, options = {}) => {
            const lang = i18n.language.split('-')[0];
            if (typeof to === 'string') {
                const newPath = to.startsWith('/') ? `/${lang}${to}` : `/${lang}/${to}`;
                navigate(newPath, options);
            } else if (typeof to === 'object' && to.pathname) {
                const newPath = `/${lang}${to.pathname.startsWith('/') ? to.pathname : '/' + to.pathname}`;
                navigate({ ...to, pathname: newPath }, options);
            } else navigate(to, options);
        },
    [navigate, i18n]);

    return navigateWithLang ;
};

export default useLangNavigate;

