import React, { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Cookies from 'js-cookie';

const LanguageLogic = ({ children }) => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { lang } = useParams();
  const userLang = useSelector((state) => state.user.lang?.label);
  
  const supportedLanguages = Object.keys(i18n.options.resources);
  const defaultLang = i18n.options.fallbackLng;

  useEffect(() => {
    const getPreferredLanguage = () => {
      if (userLang && supportedLanguages.includes(userLang))
        return userLang;
      const cookieLang = Cookies.get('i18next');
      if (cookieLang && supportedLanguages.includes(cookieLang))
        return cookieLang;
      const browserLang = navigator.language.split('-')[0];
      if (supportedLanguages.includes(browserLang))
        return browserLang;      
      return defaultLang;
    };

    if (!lang) {
      const preferredLang = getPreferredLanguage();
      if (location.pathname === '/') {
        navigate(`/${preferredLang}`, { replace: true });
      }
    } else if (!supportedLanguages.includes(lang)) {
      const newPath = `/${defaultLang}${location.pathname.replace(`/${lang}`, '')}`;
      navigate(newPath, { replace: true });
    } else if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n, navigate, location, userLang, supportedLanguages, defaultLang]);


  return <>{children}</>;
};

export default LanguageLogic;