import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import DropdownButton from "../buttons/DropdownButton";
import { downloadCsv, downloadExcel, downloadPdf } from "../../utils/download";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf, faFileCsv, faFileExcel } from "@fortawesome/pro-light-svg-icons";

const DownloadTable = ({ data, fileName = '', fileTypes = ['pdf', 'csv', 'xlsx'] }) => {
    const { t } = useTranslation();

    const actions = useMemo(() => {
        const actions = [];

        if (fileTypes.includes('pdf'))
            actions.push({
                label: <>
                    <FontAwesomeIcon className='mr-2' icon={faFilePdf} />
                    {t(`utils.download.pdf`)}
                </>,
                onClick: () => { downloadPdf(data, fileName) }
            });
        if (fileTypes.includes('csv'))
            actions.push({
                label: <>
                    <FontAwesomeIcon className='mr-2' icon={faFileCsv} />
                    {t(`utils.download.csv`)}
                </>,
                onClick: () => { downloadCsv(data, fileName) }
            });
        if (fileTypes.includes('xlsx'))
            actions.push({
                label: <>
                    <FontAwesomeIcon className='mr-2' icon={faFileExcel} />
                    {t(`utils.download.xlsx`)}
                </>,
                onClick: () => { downloadExcel(data, fileName) }
            });

        return actions;
    }, [fileTypes, data, fileName, t]);

    return (
        <DropdownButton btnProps={{ variant: 'white', size: 'sm' }}
            actions={actions}
        >
            <span>{t(`utils.download.title`)}</span>
        </DropdownButton>
    );
};

export default DownloadTable