import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  restaurantCreatedAt: null,
};

const restaurantSlice = createSlice({
  name: 'restaurant',
  initialState,
  reducers: {
    setCreationDate: (state, action) => {
      state.restaurantCreatedAt = action.payload;
    },
  }
});

export const { setCreationDate } = restaurantSlice.actions;
export default restaurantSlice.reducer;
