import React, { useEffect, useState, useMemo, useCallback } from "react";
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/pro-solid-svg-icons";
import useApi from "../../../hooks/useApi";
import { getAllCompanies } from "../../../services/admin/companyService";
import Table from '../../table/Table'
import NoData from '../../errors/NoData';
import DecoratedInput from '../../inputs/DecoratedInput';
import DropdownButton from "../../buttons/DropdownButton";
import { formatPhoneNumber } from "../../../utils/formatting";
import { startControllingCompany } from "../../../redux/slices/adminSlice";

export const AdminCompanies = ({ tKey = 'dashboard.admin.company.' }) => {
  usePageTitle('adminCompanies');
  const { t } = useTranslation();
  const [companies, setCompanies] = useState([]);
  const [filtered, setFilteredCompanies] = useState([]);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    request: getCompanies,
  } = useApi(getAllCompanies);

  const handleCompanyControl = useCallback((row) => {
    dispatch(startControllingCompany(row.values));
    navigate('dashboard');
  }, [dispatch, navigate]);

  const columns = useMemo(() => [
    {      
      accessor: 'companyId',
      Cell: '',
    },
    {
      Header: t(`${tKey}name`),
      accessor: 'name',
    },
    {
      Header: t(`${tKey}image`),
      accessor: 'imagePath',
      Cell: ({ row }) => (row.original.imagePath ? <img className='list-preview-img' src={row.original.imagePath} alt={row.original.name} height={50} /> : 'No image'),
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}address`),
      accessor: 'address',
    },
    {
      Header: t(`${tKey}city`),
      accessor: (row) => <>
        <div>{ `${row.city.npa} ${row.city.label}` }</div>
        <div className="text-light">{ `${row.region.label}` }</div>
      </>
    },
    {
      Header: t(`${tKey}contact`),
      accessor: (row) => <>
        <div><a href={`mailto:${row.email}`}>{ row.email }</a></div>
        <div>{ formatPhoneNumber(row.phone) }</div>
      </>
    },
    {
      Header: t(`${tKey}employees`),
      accessor: (row) => row.usersCount,
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            { label: t(`${tKey}actions.edit`), onClick: () => handleCompanyDetail(row) },
            { label: t(`${tKey}actions.takeControl`), onClick: () => handleCompanyControl(row) },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [handleCompanyControl, t, tKey]);

  useEffect(() => {
    const fetchCompaniesData = async () => {
      await getCompanies().then((response) => {
        setCompanies(response.data);
      }).catch((error) => {
        console.log(error);
      });
    };
    fetchCompaniesData();
  }, [getCompanies]);

  useEffect(() => {
    if (query === null || query === "") setFilteredCompanies(companies);
    else setFilteredCompanies(companies.filter((company) => company.name.toLowerCase().includes(query.toLowerCase())));
  }, [companies, query]);

  const handleCompanyDetail = (row) => {
    console.log('Company detail:', row.values);
  };

  return (
    <div className="dashboard-content">
      <DecoratedInput className='bg-white pl-2 dashboard-search-bar w-100' input={{ id: 'searchCompany', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: companies ? companies.length : 0 }) }}
        hasResearchButton
        onResearchButtonClick={ (query) => { setQuery(query) } }
      />
      <div className='table-container mt-4'>
        {filtered?.length === 0 ? <NoData />
          : <Table rowClassName='p-4' columns={columns} data={filtered} onClickRow={handleCompanyDetail} />}    
      </div>
    </div>
  );
};
  
export default AdminCompanies;