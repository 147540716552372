import React from "react";

export const AdminOrders = () => {
  return (
    <div>
      Admin orders
    </div>
  );
};
  
export default AdminOrders;