import React from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '../utils/Alert';
function NoData() {
    const { t } = useTranslation();  
    return (
        <Alert variant='white'>
            <p className='italic text-dark'>{t('utils.errors.noData.title')}</p> 
            <p>{t('utils.errors.noData.text')}</p>
        </Alert>
    );
};

export default NoData;