import React from 'react';
import { useAuth } from '../../hooks/useAuth';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const withPrivateRoute = (Component, redirectUrl = 'login') => {
  return (props) => {
    const { i18n } = useTranslation();
    const lang = i18n.language.split('-')[0];
    const { isAuthenticated } = useAuth();

    if (!isAuthenticated)
      return <Navigate to={`/${lang}/${redirectUrl}`} />;    

    return <Component {...props} />;
  };
};

export const withAuthenticatedRoute = (Component, redirectUrl = 'dashboard') => {
  return (props) => {
    const { i18n } = useTranslation();
    const lang = i18n.language.split('-')[0];
    const { isAuthenticated } = useAuth();

    if (isAuthenticated)
      return <Navigate to={`/${lang}/${redirectUrl}`} />

    return <Component {...props} />;
  };
};