import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getListOptions } from '../../../../services/listService';
import { toast } from 'react-toastify';
import Form from '../../../inputs/Form';
import IBAN from 'iban';

const Bank = ({ tKey = 'dashboard.settings.bank.', data = null, onFormDataChange }) => {
  const { t } = useTranslation();
  const [restaurantFields, setRestaurantFields] = useState([])
  const [bankFields, setBankFields] = useState([])
  const [restaurantData, setRestaurantData] = useState({});
  const [bankData, setBankData] = useState({});
  const [cityOptions, setCityOptions] = useState([]);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      const newRestaurantData = {};
      const newBankData = {};
  
      restaurantFields.forEach(field => {
        if (data.hasOwnProperty(field.name)) {
          newRestaurantData[field.name] = data[field.name];
        }
      });
  
      bankFields.forEach(field => {
        if (data.hasOwnProperty(field.name)) {
          newBankData[field.name] = data[field.name];
        }
      });
  
      setRestaurantData(newRestaurantData);
      setBankData(newBankData);
    }
  }, [data, restaurantFields, bankFields]);

  useEffect(() => {
    const fetchCityListData = async () => {
      try {
        const citiesList = await getListOptions('city');
        setCityOptions(citiesList.data);
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      };
    };
    fetchCityListData();
  }, []);

  useEffect(() => {
    if(cityOptions && cityOptions.length > 0) {
      setRestaurantFields([        
        {
          name: 'legalName',
          label: t(`${tKey}legalName.label`),
          placeholder: t(`${tKey}legalName.placeholder`),
          type: 'text',
          class: 'w-100',
          validate: (value) => {
            if (!value) return t(`${tKey}errors.legalName.required`);
            if (value.length < 3) return t(`${tKey}errors.legalName.min`);
            if (value.length > 50) return t(`${tKey}errors.legalName.max`);
            return '';
          },
        },        
        {
          name: 'billingEmail',
          label: t(`${tKey}billingEmail.label`),
          placeholder: t(`${tKey}billingEmail.placeholder`),
          type: 'email',
          class: 'w-100',
          validate: (value) => {
            if (!value) return t(`${tKey}errors.billingEmail.required`);
            if (!/\S+@\S+\.\S+/.test(value)) return t('register.errors.billingEmail.invalid');
            return '';
          },
        },
        {
          name: 'billingAddress',
          label: t(`${tKey}billingAddress.label`),
          placeholder: t(`${tKey}billingAddress.placeholder`),
          type: 'text',
          class: 'w-100',
          validate: (value) => {
            if (!value) return t(`${tKey}errors.billingAddress.required`);
            if (value.length < 3) return t(`${tKey}errors.billingAddress.min`);
            if (value.length > 100) return t(`${tKey}errors.billingAddress.max`);
            return '';
          },        
        },
        {
          name: 'billingCity',
          label: t(`${tKey}billingCity.label`),
          placeholder: t(`${tKey}billingCity.placeholder`),
          type: 'dropdown',
          class: 'w-100',
          options: cityOptions,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.billingCity.required`);
            return '';
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `
          },
        },    
      ])
      setBankFields([
        {
          name: 'bankName',
          label: t(`${tKey}bankName.label`),
          placeholder: t(`${tKey}bankName.placeholder`),
          type: 'text',
          class: 'w-100',
          isLocked: true,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.bankName.required`);
            if (value.length < 3) return t(`${tKey}errors.bankName.min`);
            if (value.length > 50) return t(`${tKey}errors.bankName.max`);
            return '';
          },
        },
        {
          name: 'bankAddress',
          label: t(`${tKey}bankAddress.label`),
          placeholder: t(`${tKey}bankAddress.placeholder`),
          type: 'text',
          class: 'w-100',
          isLocked: true,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.bankAddress.required`);
            if (value.length < 3) return t(`${tKey}errors.bankAddress.min`);
            if (value.length > 100) return t(`${tKey}errors.bankAddress.max`);
            return '';
          },
        },
        {
          name: 'bankCity',
          label: t(`${tKey}bankCity.label`),
          placeholder: t(`${tKey}bankCity.placeholder`),
          type: 'dropdown',
          class: 'w-100',
          options: cityOptions,
          isLocked: true,
          validate: (value) => {
            if (!value) return t(`${tKey}errors.bankCity.required`);
            return '';
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `
          },
        },
        {
          name: 'iban',
          label: t(`${tKey}iban.label`),
          placeholder: t(`${tKey}iban.placeholder`),
          type: 'text',
          class: 'w-100',
          isLocked: true,
          maxLength: 26,
          validate: (value) => {
            if (!value.trim()) return t(`${tKey}errors.iban.required`);
            if (!IBAN.isValid(value)) return t(`${tKey}errors.iban.invalid`);
            return '';
          },      
          valueFormat: (value) => {
            if(value) {
              const cleanValue = value.replace(/[^a-z0-9]/gi, '').toUpperCase();
              const match = cleanValue.match(/.{1,4}/g);
              const formattedValue = match ? match.join(' ') : '';
              return formattedValue.substring(0, 26);
            }
            return value;
          },
        },
      ]);
    };
  }, [t, cityOptions, tKey]);

  useEffect(() => {
    onFormDataChange(isValid ? { ...restaurantData, ...bankData } : null);
  }, [restaurantData, bankData, isValid, onFormDataChange]);

  return (
    <div className='dashboard-content flex gap-2'>
      <div className='w-50 bg-white rounded p-3'>
        <Form fields={restaurantFields} currentForm={restaurantData} setFormDetails={setRestaurantData} setIsValid={setIsValid} />
      </div>
      <div className='w-50 bg-white rounded p-3'>
        <Form fields={bankFields} currentForm={bankData} setFormDetails={setBankData} setIsValid={setIsValid} />
      </div>
    </div>
  );
};

export default Bank;
