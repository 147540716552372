import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '../components/buttons/Button';
import LangButton from '../components/buttons/LangButton';
import { usePageTitle } from '../hooks/useMeta';

function Home() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  usePageTitle('home');

  return <div>
    <h1>Welcome to Offood Dashboard!</h1>
    <p>You are logged in as {user.firstname} {user.lastname}.</p>
    <LangButton />
    <Button size='md' variant='success' onClick={() => navigate('login')}>
      Go to login
    </Button>
  </div>;
}

export default Home;
