import { postRequest } from '../api/methodsCfg';

export const getRestaurantOrders = async (restaurantId, from = null, to = null) => {
    const response = await postRequest(`/order/restaurant/${restaurantId}`, {
        from, to
    });
    return response;
};

export const getRestaurantSuccessfulOrders = async (restaurantId, from = null, to = null, withAllItems = false) => {
    const response = await postRequest(`/order/restaurant/${restaurantId}/success`, {
        from,
        to,
        withAllItems,
    });
    return response;
};