import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

function StepOne({ setRole, setIsValid }) {
  const [selectedRole, setSelectedRole] = useState(null);
  const { t } = useTranslation();

  const handleRoleSelection = role => {
    setSelectedRole(role);
    setRole(role);
    setIsValid(true);
  };

  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.step1.title')}</h2>
        <div className='p3' dangerouslySetInnerHTML={{ __html: t('register.step1.alreadyRegistered') }} />
      </div>
      <p className='mb-4'>{t('register.step1.role')}</p>
      <div className="btn-group-vertical">
        <input type="radio" id="restaurant-option"
          name="radio-group"
          className="btn-radio"
          checked={selectedRole === 'restaurant'}
          onChange={() => handleRoleSelection('restaurant')}  
        />
        <label htmlFor="restaurant-option" className={`btn btn-label btn-md btn-tertiary btn-block text-left ${selectedRole === 'restaurant' ? 'btn-checked' : ''}`}>
          {t('register.step1.restaurant')}
        </label>   
        <input type="radio" id="company-option" 
          name="radio-group" className="btn-radio"
          checked={selectedRole === 'company'}
          onChange={() => handleRoleSelection('company')}
        />
        <label htmlFor="company-option" className={`btn btn-label btn-md btn-tertiary btn-block text-left ${selectedRole === 'company' ? 'btn-checked' : ''}`}>
          {t('register.step1.company')}
        </label>             
      </div>
    </div>
  );
}

export default StepOne;