import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import useApi from '../../../hooks/useApi';
import { useCreatedDate } from '../../../hooks/useCreatedDate';
import DecoratedInput from '../../inputs/DecoratedInput';
import Tabs from '../../tabs/Tabs';
import Payments from './reports/Payments';
import Revenues from './reports/Revenues';
import Sales from './reports/Sales';
import { getRestaurantOrders } from '../../../services/orderService';
import { getRestaurantInvoices } from '../../../services/invoiceService';
import DatePickerButton from '../../buttons/DatePickerButton';
import { subWeeks, max } from 'date-fns';

export const RestaurantReports = ({ tKey = 'dashboard.reports.' }) => {
  usePageTitle('restaurantReports');
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [invoices, setInvoices] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [uniqueStatuses, setUniqueStatuses] = useState([]);
  const [uniqueConsumptionModes, setUniqueConsumptionModes] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState({ value: 5, label: t(`utils.status.successfullyDone`)});
  const [selectedConsumptionMode, setSelectedConsumptionMode] = useState(null);
  const [query, setQuery] = useState('');
  const {
    request: getOrdersRequest,
  } = useApi(getRestaurantOrders); 
  const {
    request: getInvoicesRequest,
  } = useApi(getRestaurantInvoices);
  const restaurantId = useSelector((state) => state.user.restaurantId);
  const { restaurantCreatedAt } = useCreatedDate(restaurantId);
  const endDate = new Date();
  const weekAgo = subWeeks(endDate, 1);
  const startDate = max([weekAgo, restaurantCreatedAt]); // 1 week ago or restaurant creation date if created less than a week ago
  const [dateRange, setDateRange] = useState([
    {
      startDate: startDate,
      endDate: endDate,
      key: 'selection'
    }
  ]);
  const [activeTab, setActiveTab] = useState(null);

  const tabs = useMemo(() => [
   {
    name: 'sales',
    content: () => <Sales data={orders} filteredData={filteredOrders} />,
   },
   {
    name: 'revenues',
    content: () => <Revenues data={orders} filteredData={filteredOrders} />,
   },
   {
    name: 'payments',
    content: () => <Payments data={invoices} filteredData={filteredInvoices} />,
   }
  ], [filteredOrders, filteredInvoices, orders, invoices]);

  const fetchOrdersRestaurant = useCallback(async (restaurantId, startAt, endAt) => {
    await getOrdersRequest(restaurantId, startAt, endAt)
      .then((response) => {
        const statusMap = new Map();
        const consumptionMap = new Map();        
        response.data.forEach(order => {
          if (order.outcome && !statusMap.has(order.outcome.orderStatusId))
            statusMap.set(order.outcome.orderStatusId, order.outcome);    
          if (order.consumptionmode && !consumptionMap.has(order.consumptionmode.consumptionModeId))
            consumptionMap.set(order.consumptionmode.consumptionModeId, order.consumptionmode);
        });
        setUniqueStatuses(Array.from(statusMap.values()).map(status => ({ value: status.orderStatusId, label: t(`utils.status.${status.orderstatus.label}`) })));
        setUniqueConsumptionModes(Array.from(consumptionMap.values()).map(consumptionMode => ({ value: consumptionMode.consumptionModeId, label: t(`utils.consumptionmode.${consumptionMode.label}`) })));
        setOrders(response.data);        
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getOrdersRequest, setUniqueStatuses, setUniqueConsumptionModes, setOrders, t]);

  const fetchInvoicesRestaurant = useCallback(async (restaurantId, startAt, endAt) => {
    await getInvoicesRequest(restaurantId, startAt, endAt)
      .then((response) => {
        setInvoices(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getInvoicesRequest, setInvoices]);

  const filterOrders = useCallback((query) => {
    if(!orders) return [];
    let filteredOrders = [...orders];
    if (selectedStatus)
      filteredOrders = filteredOrders.filter(o => o.outcome.orderStatusId === selectedStatus.value);
    if (selectedConsumptionMode)
      filteredOrders = filteredOrders.filter(o => o.consumptionmode.consumptionModeId === selectedConsumptionMode.value);
    if (query) filteredOrders = filteredOrders.filter(o => o.orderId.toString().includes(query.toLowerCase()));
    return filteredOrders;
  }, [orders, selectedStatus, selectedConsumptionMode]);

  const filterInvoices = useCallback((query) => {
    if(!invoices) return [];
    let filteredInvoices = [...invoices];
    if (query) filteredInvoices = filteredInvoices.filter(i => i.invoiceId.toString().includes(query.toLowerCase()));
    return filteredInvoices;
  }, [invoices]);

  const onDateRangeChange = (range) => {
    setDateRange([range]);
  };

  useEffect(() => {
    fetchOrdersRestaurant(restaurantId, dateRange[0]?.startDate, dateRange[0]?.endDate);
    fetchInvoicesRestaurant(restaurantId, dateRange[0]?.startDate, dateRange[0]?.endDate);
  }, [restaurantId, dateRange, fetchOrdersRestaurant, fetchInvoicesRestaurant]);

  useEffect(() => {
    const filteredOrders = filterOrders(query);
    setFilteredOrders(filteredOrders);
  }, [query, orders, selectedStatus, selectedConsumptionMode, filterOrders]);

  useEffect(() => {
    const filteredInvoices = filterInvoices(query);
    setFilteredInvoices(filteredInvoices);
  }, [query, invoices, filterInvoices]);

  return (
    <div className='dashboard-content'>
      <Tabs tKey={tKey} tabs={tabs} onTabChange={setActiveTab} contentClass='flex gap-1'>
        <DecoratedInput className='bg-white dashboard-search-bar w-100' input={{ id: 'searchReport', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`) }}
          hasResearchButton
          onResearchButtonClick={ (query) => { setQuery(query) } }
        />
        {activeTab === 'sales' && <>
          <Select
            className='react-custom-select dashboard-select'
            classNamePrefix="react-select"
            placeholder={t(`${tKey}status`)}
            options={uniqueStatuses}
            value={selectedStatus}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedStatus(value)}
          />
          <Select
            className='react-custom-select dashboard-select'
            classNamePrefix="react-select"
            placeholder={t(`${tKey}consumptionMode`)}
            options={uniqueConsumptionModes}
            value={selectedConsumptionMode}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedConsumptionMode(value)}
          />
        </>}
        <DatePickerButton onDateRangeChange={onDateRangeChange} 
          minDate={restaurantCreatedAt} 
          startAt={dateRange[0]?.startDate || null}
          endAt={dateRange[0]?.endDate || null}
        />
      </Tabs>
    </div>
  );
};

export default RestaurantReports;