import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Form from '../../inputs/Form';

const Connection = ({ tKey = 'dashboard.settings.connection.', onFormDataChange }) => {
    const { t } = useTranslation();
    const [passwordFields, setPasswordFields] = useState([]);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        setPasswordFields([
            {
                name: 'oldPassword',
                label: t(`${tKey}oldPassword.label`),
                placeholder: t(`${tKey}oldPassword.placeholder`),
                type: 'password',
                class: 'w-100',
                validate: (value) => {
                    if (!value) return t(`${tKey}errors.oldPassword.required`);
                    if (value.length < 8) return t(`${tKey}errors.oldPassword.min`);
                    if (value.length > 50) return t(`${tKey}errors.oldPassword.max`);
                    return '';
                },
            },
            {
                name: 'password',
                label: t(`${tKey}password.label`),
                placeholder: t(`${tKey}password.placeholder`),
                type: 'password',
                class: 'w-100',
                info: t(`${tKey}password.info`),
                validate: (value) => {
                    if (!value) return t(`${tKey}errors.password.required`);
                    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/.test(value)) return t('register.errors.password.invalid');
                    return '';
                },
            },
            {
                name: 'confirmPassword',
                label: t(`${tKey}confirmPassword.label`),
                placeholder: t(`${tKey}confirmPassword.placeholder`),
                type: 'password',
                class: 'w-100',
                validate: (value, form) => {
                    if (!value) return t(`${tKey}errors.confirmPassword.required`);
                    if (!form || (value !== form.password)) return t(`${tKey}errors.confirmPassword.match`);
                    return '';
                },
            },
        ]);
    }, [t, tKey]);

    useEffect(() => {
        onFormDataChange(isValid ? formData : null);
    }, [formData, isValid, onFormDataChange]);

    return (<>
        <div className='dashboard-content flex gap-2'>
            <div className='w-50 bg-white rounded p-3 flex column'>
                <Form fields={passwordFields} currentForm={formData} setFormDetails={setFormData} setIsValid={setIsValid} />
            </div>
        </div>
    </>);
};

export default Connection;