import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getListOptions } from '../../../../services/listService';
import { toast } from 'react-toastify';
import Form from '../../../inputs/Form';
import Spinner from '../../../utils/Spinner'
import useApi from '../../../../hooks/useApi';
import { creatNewDish, updateDish, getDish, deleteDish, switchActivateDish } from '../../../../services/cateringService';
import Button from '../../../buttons/Button';
import { formatSwissPrice } from '../../../../utils/formatting';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import ProductAppPreview from '../../../app/ProductAppPreview';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import Modal from '../../../utils/Modal';
import useModal from '../../../../hooks/useModal';
import { usePageTitle } from '../../../../hooks/useMeta';
import NavigationContext from '../../../../contexts/NavigationContext';

export const CateringView = ({ tKey = 'cateringView.' }) => {
    const { dishId } = useParams();
    usePageTitle(dishId ? 'cateringView' : 'newCatering');
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isModalOpen, openModal, closeModal } = useModal();
    const [modalContent, setModalContent] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [fields, setFields] = useState([])
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [formData, setFormData] = useState({});
    const [isValid, setIsValid] = useState(false);
    const [previewImg, setPreviewImg] = useState(null);
    const restaurantId = useSelector((state) => state.user.restaurantId);
    const { setHasChanged } = useContext(NavigationContext);
    const {
        request: dishCreateRequest,
    } = useApi(creatNewDish);
    const {
        request: dishGetRequest,
    } = useApi(getDish);
    const {
        request: dishUpdateRequest,
    } = useApi(updateDish);
    const {
        request: deleteDishRequest,
    } = useApi(deleteDish);
    const {
        request: switchActivateRequest,
    } = useApi(switchActivateDish);
    
    const convertDishToFormData = useCallback((dish) => {
        setFormData({ ...dish, 
            type: { label: dish.dishtype ? t(`utils.dishTypes.${dish.dishtype.label}`) : null, value: dish.dishtype || null },
            allergies: dish.allergies.map((a) =>  {
                return { label: t(`utils.allergies.${a.label}`), value: a.label }                                
            })
        });
    }, [t]);

    useEffect(() => {
        const fetchListData = async () => {
          setIsLoading(true);
          try {
                const typesList = await getListOptions('dishtype', 'catering/');
                const allergiesList = await getListOptions('allergy');
                setDropDownOptions({
                    typesList: typesList.data,
                    allergiesList: allergiesList.data,
                })
            } catch (error) {
                console.error(error);
                toast.error(error.message);
            } finally {
                setIsLoading(false);
            }
        };
        fetchListData();
    }, []);

    useEffect(() => {
        setHasChanged(false);
        if(dishId && dropDownOptions.allergiesList) {
            setIsLoading(true);
            dishGetRequest(dishId)
                .then((res) => {                
                    const dish = res.data;                    
                    convertDishToFormData(dish);
                    setPreviewImg(dish.imagePath);
                })
                .catch(error => {
                    console.error(error);
                    toast.error(t(`${tKey}errors.getDish`));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [dishId, dropDownOptions.allergiesList, convertDishToFormData, dishGetRequest, t, tKey, setHasChanged]);

    useEffect(() => {
        if(formData.imagePath) setPreviewImg(URL.createObjectURL(formData.imagePath));
    }, [formData.imagePath]);
    
    useEffect(() => {
        if (dropDownOptions.typesList && dropDownOptions.typesList.length > 0 
            && dropDownOptions.allergiesList && dropDownOptions.allergiesList.length > 0)
            setFields([
                {
                    name: 'imagePath',
                    placeholder: t(`${tKey}imagePath.placeholder`),
                    type: 'file',
                    accept: 'image/*',
                    class: 'w-100',
                },
                {
                    name: 'name',
                    placeholder: t(`${tKey}name.placeholder`),
                    type: 'text',
                    class: 'w-100',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.name.required`);
                        if(value.length < 2) return t(`${tKey}errors.name.min`);
                        if(value.length > 50) return t(`${tKey}errors.name.max`);
                        return '';
                    },
                },
                {
                    name: 'description',
                    placeholder: t(`${tKey}description.placeholder`),
                    type: 'textarea',
                    class: 'w-100',
                    rows: 4,
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.description.required`);
                        if(value.length < 2) return t(`${tKey}errors.description.min`);
                        if(value.length > 255) return t(`${tKey}errors.description.max`);
                        return '';
                    },
                },
                {
                    name: 'isVegetarian',
                    label: t(`${tKey}isVegetarian.label`),
                    type: 'checkbox',
                    class: 'w-30 flex-grow bg-white p-3 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'isVegan',
                    label: t(`${tKey}isVegan.label`),
                    type: 'checkbox',
                    class: 'w-30 flex-grow bg-white p-3 rounded mx-2',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'isHomemade',
                    label: t(`${tKey}isHomemade.label`),
                    type: 'checkbox',
                    class: 'w-30 bg-white p-3 rounded',
                    checkboxStyle: {
                        coreStyle: {
                            borderRadius: 50,
                            borderWidth: 1,
                            size: 20,
                            borderColor: '#1AE170',
                            style: {
                                overflow: 'hidden',
                            },
                        },
                        iconStyle: {
                            backgroundColor: '#1AE170',
                            color: 'white',
                            display: 'flex',
                            flex: 1,
                            justifyContent: 'center',
                            alignItems: 'center',
                            alignSelf: 'stretch',
                        },
                        
                    },
                },
                {
                    name: 'allergies',
                    placeholder: t(`${tKey}allergies.placeholder`),
                    type: 'dropdown',
                    multiple: true,
                    class: 'w-100',
                    options: dropDownOptions.allergiesList,
                    optionFormat: (value) => {
                        return t(`utils.allergies.${value.label}`);
                    },                    
                },
                {
                    name: 'type',
                    placeholder: t(`${tKey}type.placeholder`),
                    type: 'dropdown',
                    class: 'w-100',
                    options: dropDownOptions.typesList.map(option => ({
                        ...option,
                        label: t(`utils.dishTypes.${option.label}`)
                    })),
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.type.required`);
                        return '';
                    },
                    optionFormat: (option) => {
                        return option.label;
                    },
                },
                {
                    name: 'price',
                    placeholder: t(`${tKey}price.placeholder`),
                    type: 'number',
                    class: 'w-100',
                    inputContainerClassName: 'input-chf',
                    validate: (value) => {
                        if(!value) return t(`${tKey}errors.price.required`);
                        return '';
                    },
                    onBlur: (event, updateFormState, field) => {
                        const formattedValue = formatSwissPrice(event.target.value);     
                        updateFormState(field, formattedValue);                   
                    },
                },                
            ]);
    }, [dropDownOptions, formData, t, tKey]);

    const attemptCreateDish = async (e, activate = false) => {
        e.preventDefault();
        await dishCreateRequest({ ...formData, isActive: activate, isPublished: true, restaurantId,
            allergies: dropDownOptions.allergiesList?.filter(item => formData.allergies.map(a => a.value).includes(item.label)),
        })
        .then(() => {
            toast.success(t(`${tKey}success`));
            navigate('dashboard/catering');
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptUpdateDish = async (e, activate = false) => {
        e.preventDefault();
        await dishUpdateRequest({ ...formData, isActive: activate, isPublished: true, dishId, restaurantId,
            allergies: dropDownOptions.allergiesList?.filter(item => formData.allergies.map(a => a.value).includes(item.label)),
        })
        .then(() => {
            toast.success(t(`${tKey}success`));
            navigate('dashboard/catering');
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptSwitchActiveDish = async () => {
        await switchActivateRequest(dishId)
        .then((res) => {     
            convertDishToFormData(res.data);       
            toast.success(t(`${tKey}success`));
        })
        .catch((error) => {
            toast.error(t(`${tKey}error`));
            console.log(error);
        });
    };

    const attemptDelete = async () => {
        setModalContent(<>
            <h5 className='mt-0'>{t(`${tKey}delete.title`)}</h5>
            <p>{t(`${tKey}delete.text`, { dishName: formData.name })}</p>
        </>);
        openModal();
    };

    const deleteCurrentDish = async () => {
        await deleteDishRequest(dishId)
        .then((res) => {
            if (res.status === 200) {
                toast.success(t(`${tKey}delete.success`, { dishName: formData.name }));
                navigate('dashboard/catering');
            }
        })
        .catch((err) => {
            console.error(err);
            toast.error(t(`${tKey}delete.error`, { dishName: formData.name }));
        })
        .finally(async () => {
            setModalContent(null);
            closeModal();
        });
    };

    return (
        <>
            <div className='dashboard-content w-50'>
                {isLoading 
                ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
                : <Form fields={fields} 
                    currentForm={formData} 
                    setFormDetails={setFormData} 
                    setIsValid={setIsValid} 
                    onInitialChange={() => setHasChanged(true)}
                    fieldClass='bg-white' 
                />}
            </div>
            <div className='dashboard-preview w-50'>
                <div className='preview-section'>
                    <ProductAppPreview img={previewImg} title={formData.name !== '' ? formData.name : null} dishCategory={formData.category?.value} />
                </div>
                <div className='publish-section flex align-center justify-between'>
                    <div>
                        {dishId && <Button variant='white' onClick={attemptDelete}>
                            <FontAwesomeIcon size='2x' icon={faTrashAlt} />
                        </Button>}
                    </div>
                    <div className='flex gap-1'>
                        <Button disabled={!isValid} onClick={dishId ? attemptUpdateDish : attemptCreateDish}>
                            {t(`${tKey}${dishId ? 'btnUpdate' : 'btnPublish'}`)}
                        </Button>
                        {dishId
                            ? <Button variant={formData.isActive ? 'danger' : 'success'} disabled={!isValid} onClick={attemptSwitchActiveDish}>
                                {t(`${tKey}${formData.isActive ? 'btnDeactivate' : 'btnActivate'}`)}
                            </Button>
                            : <Button variant='success' disabled={!isValid} onClick={dishId ? (e) => attemptUpdateDish(e, true) : (e) => attemptCreateDish(e, true)}>
                                {t(`${tKey}${dishId ? 'btnActivate' : 'btnPublishActivate'}`)}
                            </Button>
                        }                            
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteCurrentDish}>
                {modalContent}
            </Modal>
        </>
    );
};

export default CateringView;