import { getRequest, putRequest } from '../../api/methodsCfg';
import  { getLatLongFromAddress } from '../../utils/gis';

export const getAllRestaurants = async () => {
    const response = await getRequest('/restaurant/all');
    return response;
};

export const getRestaurant = async (id) => {
    const response = await getRequest(`/restaurant/${id}`);
    return response;
};

export const updateRestaurantSettingsByAdmin = async (restaurantId, payload) => { 
    let x, y = null;
    const { latitude, longitude } = await getLatLongFromAddress(payload.address, payload.city?.value?.npa, payload.city?.value?.label);
    x = latitude;
    y = longitude;
    const cleanPayload = {
        ...payload,
        x,
        y,
        city: payload.city.value ? payload.city.value.cityId : payload.cityId,
        consumptionmodes: payload.consumptionmodes?.map((mode) => mode.value?.consumptionModeId || mode.consumptionModeId),
        restauranttype: payload.restauranttype.value ? payload.restauranttype.value.restaurantTypeId : payload.restaurantTypeId,
        schedules: payload.schedules?.map((s) => ({
            day: s.day,
            noonStartAt: s.noon.startAt,
            noonEndAt: s.noon.endAt,
            eveningStartAt: s.evening.startAt,
            eveningEndAt: s.evening.endAt,
        })),
        cateringschedules: payload.cateringschedules?.map((s) => ({
            day: s.day,
            startAt: s.startAt,
            endAt: s.endAt,
        })),
        cateringpromotions: payload.cateringpromotions?.map((p) => ({
            percentage: p.percentage,
            thresholdAmount: p.thresholdAmount,
        })),
    };
    const response = await putRequest(`/restaurant/admin/${restaurantId}/${payload.name}`, cleanPayload, true);
    return response;
};

export const updateBankInformationSettingsByAdmin = async (restaurantId, payload) => {
    const response = await putRequest(`/restaurant/bankByAdmin/${restaurantId}`, payload);
    return response;
};