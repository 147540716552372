import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userId: null,
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  is2FA: false,
  occupiedFunction: '',
  token: null,
  roles: null,
  lang: null,
  restaurantId: null,
  originalRestaurantId: null,
  companyId: null,
  originalCompanyId: null,
  imagePath: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.userId = action.payload.userId;
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.is2FA = action.payload.is2FA;
      state.occupiedFunction = action.payload.occupiedFunction;      
      state.roles = action.payload.roles;
      state.lang = action.payload.language;
      state.imagePath = action.payload.imagePath;
      state.restaurantId = action.payload.restaurantId;
      state.originalRestaurantId = action.payload.restaurantId;
      const companyId = action.payload.companies ? action.payload.companies.find((company) => company.usercompany?.isMain)?.companyId : null;
      state.companyId = companyId;
      state.originalCompanyId = companyId;
      state.token = action.payload.token;
    },
    updateUser: (state, action) => {
      state.firstname = action.payload.firstname;
      state.lastname = action.payload.lastname;
      state.email = action.payload.email;
      state.phone = action.payload.phone;
      state.occupiedFunction = action.payload.occupiedFunction;
      state.lang = action.payload.language;
      state.is2FA = action.payload.is2FA;
      state.imagePath = action.payload.imagePath;
    },
    updateLang: (state, action) => {
      state.lang = action.payload;
    },
    clearUser: () => initialState,
    setRestaurantId: (state, action) => {
      state.restaurantId = action.payload;
    },
    restoreRestaurantId: (state) => {
      state.restaurantId = state.originalRestaurantId;
    },
    setCompanyId: (state, action) => {
      state.companyId = action.payload;
    },
    restoreCompanyId: (state) => {
      state.companyId = state.originalCompanyId;
    },
  },
});

export const { setUser, updateUser, updateLang, clearUser, setRestaurantId, restoreRestaurantId, setCompanyId, restoreCompanyId } = userSlice.actions;

export default userSlice.reducer;
