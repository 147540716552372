import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAsc, faSortDesc } from '@fortawesome/pro-solid-svg-icons';
import { Droppable, Draggable, DragDropContext } from 'react-beautiful-dnd';
import React, { useState } from 'react';
import { useTable, useSortBy } from 'react-table';
import EmptyTableMessage from './EmptyTableMessage';

const Table = ({ className, rowClassName = '', columns = [], data = [], noHeader = false, isDroppable = false, collapseContent = null, onOrderChange = () => {}, onClickRow = () => {}, children }) => {
  const [draggableData, setDraggableData] = useState(data);
  const [expandedRows, setExpandedRows] = useState({});

  const onDragEnd = (result) => {
    if (!result.destination || result.destination.index === result.source.index) return;
    const reorderedData = Array.from(draggableData);
    const [removed] = reorderedData.splice(result.source.index, 1);
    reorderedData.splice(result.destination.index, 0, removed);
    setDraggableData(reorderedData);
    if (onOrderChange) onOrderChange(reorderedData);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable({
    columns,
    data
  }, useSortBy);

  const hasSortedColumns = sortBy.length > 0;

  const renderRow = (row, index) => {
    prepareRow(row);
    let isRowExpanded;
    if(collapseContent) isRowExpanded = expandedRows[row.id];
    return (<>
      <tr className={`${rowClassName} ${onClickRow ? 'clickable-row' : ''} ${isRowExpanded ? 'expanded-row' : ''}`} {...row.getRowProps()} onClick={() => handleRowClick(row)}>
        {row.cells.map((cell, index) => {
          const isDragHandle = cell.column.isDragHandle;
          return (
            <td key={index} className={`${isDragHandle ? 'drag-handle--disabled' : ''} ${rowClassName}`}	 {...cell.getCellProps()}>{cell.render('Cell')}</td>
          )
        })}
      </tr>
      {collapseContent && expandedRows[row.id] && (
        <tr className='table-collapse'>
          <td colSpan={row.cells.length}>
            <div className="collapse-content">
              {React.cloneElement(collapseContent, { row })}
            </div>
          </td>
        </tr>
      )}
    </>);
  };

  const renderDraggableRow = (row, index) => {
    prepareRow(row);
    let isRowExpanded;
    if(collapseContent) isRowExpanded = expandedRows[row.id];
    return (<>
      <Draggable key={row.id} draggableId={row.id} index={index}>
        {(provided, snapshot) => {
          const draggableClassName = `draggable-item ${snapshot.isDragging ? ' draggable-item--is-dragging' : ''}`;
          return (
            <tr
              {...row.getRowProps()}
              {...provided.draggableProps}
              ref={provided.innerRef}
              style={provided.draggableProps.style}
              className={`${onClickRow ? 'clickable-row' : ''} ${isRowExpanded ? 'expanded-row' : ''} ${draggableClassName} ${rowClassName}`}
              onClick={() => handleRowClick(row)}
            >
              {row.cells.map(cell => {
                const cellProps = cell.getCellProps();
                const isDragHandle = cell.column.isDragHandle;
                return (
                  <td key={cell.column.id}
                    {...cellProps}
                    {...(isDragHandle ? provided.dragHandleProps : {})}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          )
        }}
      </Draggable>
      {collapseContent && expandedRows[row.id] && (
        <tr className='table-collapse'>
          <td colSpan={row.cells.length}>
            <div className="collapse-content">
              {React.cloneElement(collapseContent, { row })}
            </div>
          </td>
        </tr>
      )}
    </>);
  };

  const handleRowClick = (row) => {
    setExpandedRows(prev => ({
      ...prev,
      [row.id]: !prev[row.id]
    }));
    if (onClickRow) onClickRow(row);
  };

  return (<div className='table-container'>
    {children}
    <DragDropContext onDragEnd={onDragEnd}>
      <table {...getTableProps()} className={`bg-white p-3 border-rounded w-100 ${className || ''}`}>
        <thead className={noHeader ? 'hidden' : ''}>
          {headerGroups.map((headerGroup, index) => (
            <tr key={index} className={rowClassName} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th key={index} className={rowClassName} {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <FontAwesomeIcon className='ml-2' transform={{ y: 4 }} icon={faSortAsc} />
                        : <FontAwesomeIcon className='ml-2' transform={{ y: -1 }} icon={faSortDesc} />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        {isDroppable && !hasSortedColumns ? (
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...getTableBodyProps()} ref={provided.innerRef} {...provided.droppableProps}>
                {rows.length ? rows.map(renderDraggableRow) : <EmptyTableMessage colSpan={headerGroups[0].headers.length} />}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        ) : (
          <tbody {...getTableBodyProps()}>
            {rows.length ? rows.map(renderRow) : <EmptyTableMessage colSpan={headerGroups[0].headers.length} />}
          </tbody>
        )}
      </table>
    </DragDropContext>
  </div>);
};

export default Table;