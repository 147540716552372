import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getListOptions } from '../../../services/listService';
import { toast } from 'react-toastify';
import Form from '../../inputs/Form';
import Spinner from '../../utils/Spinner';

function StepTwoRestaurant({ currentForm, setRestaurantDetails, setIsValid = () => {} }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [dropDownOptions, setDropDownOptions] = useState({
    citiesList: [],
    categoriesList: [],
    servicesList: [],
  });
  const [fields, setFields] = useState([])

  useEffect(() => {
    const fetchListData = async () => {
      setIsLoading(true);
      try {
        const citiesList = await getListOptions('city');
        const categoriesList = await getListOptions('restauranttype');
        const servicesList = await getListOptions('consumptionmode');
        setDropDownOptions({
          citiesList: citiesList.data,
          categoriesList: categoriesList.data,
          servicesList: servicesList.data,
        });
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    };
    fetchListData();
  }, []);

  useEffect(() => {
    if (dropDownOptions.citiesList && dropDownOptions.citiesList.length > 0 
        && dropDownOptions.categoriesList && dropDownOptions.categoriesList.length > 0 
        && dropDownOptions.servicesList && dropDownOptions.servicesList.length > 0)
      setFields([
        {
          name: 'name',
          label: t('register.step2.name.label'),
          placeholder: t('register.step2.name.placeholder'),
          type: 'text',
          class: 'w-100',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.name.required');
            if (value.length < 3) return t('register.errors.name.min');
            if (value.length > 50) return t('register.errors.name.max');
            return '';
          },
        },
        {
          name: 'address',
          label: t('register.step2.address.label'),
          placeholder: t('register.step2.address.placeholder'),
          type: 'text',
          class: 'w-60 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.address.required');
            if (value.length > 100) return t('register.errors.address.max');
            return '';
          },
        },
        {
          name: 'city',
          label: t('register.step2.city.label'),
          placeholder: t('register.step2.city.placeholder'),
          type: 'dropdown',
          class: 'w-40 pl-2',
          options: dropDownOptions.citiesList,
          validate: (value) => {
            if (!value) return t('register.errors.city.required');
            return '';
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `
          },
        },
        {
          name: 'restaurantType',
          label: t('register.step2.category.label'),
          placeholder: t('register.step2.category.placeholder'),
          type: 'dropdown',
          class: 'w-100',
          options: dropDownOptions.categoriesList,
          validate: (value) => {
            if (!value) return t('register.errors.category.required');
            return '';
          },
          optionFormat: (value) => {
            return t(`utils.restauranttype.${value.label}`)
          },
        },
        {
          name: 'consumptionMode',
          label: t('register.step2.service.label'),
          placeholder: t('register.step2.service.placeholder'),
          type: 'dropdown',
          multiple: true,
          class: 'w-100',
          options: dropDownOptions.servicesList,
          validate: (value) => {
            if (!value.length) return t('register.errors.service.required');
            return '';
          },
          optionFormat: (value) => {
            return t(`utils.consumptionmode.${value.label}`)
          },
          info: t('register.step2.service.info'),
        },
        {
          name: 'conditions',
          label: t('register.step2.conditions.label'),
          type: 'checkbox',
          validate: (value) => {
            if (!value) return t('register.errors.conditions.required');
            return '';
          },
          info: t('register.step2.conditions.info'),
        },
      ])
  }, [dropDownOptions, t]);

  const handleRestaurantDetails = (data) => {
    setRestaurantDetails(data);
  };

  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.step2.title')}</h2>
        <div className='p3'>{t('register.step2.details')}</div>
      </div>
      {isLoading 
        ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
        : <Form fields={fields} currentForm={currentForm} setFormDetails={handleRestaurantDetails} setIsValid={setIsValid} />}
    </div>
  );
}

export default StepTwoRestaurant;