import React from "react";

function Alert({ children, className = '', variant = 'danger' }) {
    return (
        <div className={`alert bg-${variant} ${className}`} role="alert">
            {children}
        </div>
    );
}

export default Alert;