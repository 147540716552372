import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { components } from 'react-select';

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {props.isMulti 
          ? <FontAwesomeIcon icon={faPlus} />
          : <FontAwesomeIcon size='xs' color='white' icon={faChevronDown} />
        }
      </components.DropdownIndicator>
    );
};

export default DropdownIndicator;