import { getRequest } from '../api/methodsCfg';
const authorizedLists = [
  'country',
  'city',
  'region',
  'commune',
  'allergy',
  'consumptionmode',
  'dishcategory',
  'dishtype',
  'foodtag',
  'offertype',
  'paymentmethod',
  'pricecategory',
  'restauranttype',
  'language',
  'refundmotive',
];

export const getListOptions = async (listName, extra = '') => {
  if (!authorizedLists.includes(listName))
    throw new Error('List not authorized');
  try {
    const response = await getRequest(`/${listName}/${extra}all`);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error;
  }
};

export const getListItemById = async (listName, id) => {
  if (!authorizedLists.includes(listName))
    throw new Error('List not authorized');
  try {
    const response = await getRequest(`/${listName}/${id}`);
    return { data: response.data, status: response.status };
  } catch (error) {
    throw error;
  }
};