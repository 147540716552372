import React from 'react';
import { useTranslation } from 'react-i18next';
import Table from '../../../table/Table';
import { formatDate, formatMoney } from '../../../../utils/formatting';
import Button from '../../../buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownToArc } from '@fortawesome/pro-light-svg-icons';
import { downloadInvoice } from '../../../../utils/download';
import useApi from '../../../../hooks/useApi';
import { getInvoice } from '../../../../services/invoiceService';

const Payments = ({ data, filteredData, tKey = 'dashboard.payments.' }) => {
    const { t } = useTranslation();
    const {
        request: getInvoiceRequest,
    } = useApi(getInvoice);
    const columns = [
        {
            Header: t(`${tKey}id`),
            accessor: 'invoiceId',
        },
        {
            Header: t(`${tKey}date`),
            accessor: 'startAt',
            Cell: ({ row }) => {
                const { startAt, endAt } = row.original;
                return <div className='p3'>
                    <div>{formatDate(startAt, false)}</div>            
                    <div>{formatDate(endAt, false)}</div>            
                </div>
            },
        },
        {
            Header: t(`${tKey}fees`),
            accessor: 'fees',
            Cell: ({ value }) => {
                return <>{formatMoney(value)}</>;
            },
        },
        {
            Header: t(`${tKey}netAmount`),
            accessor: 'netAmount',
            Cell: ({ value }) => {
                return <>{formatMoney(value)}</>;
            },
        },
        {
            Header: t(`${tKey}actions`),
            accessor: () => 'actions',            
            Cell: ({ row }) => {                
                return <Button link onClick={() => getInvoiceAndDownload(row.original.invoiceId)}>
                    <FontAwesomeIcon icon={faArrowDownToArc} />
                </Button>;
            },
            disableSortBy: true,
        },
    ];

    const getInvoiceAndDownload = async (invoiceId) => {
        const invoice = await getInvoiceRequest(invoiceId);
        downloadInvoice(invoice);
    };

    return (
        <div className='w-100 bg-white rounded flex column gap-3'>
            <div className='table-container'>
                <Table columns={columns} data={filteredData} />
            </div>
        </div>           
    );
};

export default Payments;
