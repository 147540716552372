import React from 'react';
import Button from '../buttons/Button';
import { useTranslation } from 'react-i18next';


const Modal = ({ children, id, isOpen, noFooter = false, onClose, onOK, onDelete }) => {  
    const { t } = useTranslation();  
    if (!isOpen) return null;

    const handleBackgroundClick = (e) => {
        if (e.target === e.currentTarget) onClose();
    };

    return (
        <div id={id ? id : ''} className='modal' onClick={handleBackgroundClick}>
            <div className='modal-content' onClick={(e) => e.stopPropagation()}>
                {children}
                {!noFooter && <div className='modal-footer'>
                    <Button variant='secondary' onClick={onClose}>{t('utils.common.close')}</Button>
                    {onOK && <Button variant='success' onClick={() => onOK()}>{t('utils.common.ok')}</Button>}
                    {onDelete && <Button variant='danger' onClick={() => onDelete()}>{t('utils.common.delete')}</Button>}
                </div>}
            </div>
        </div>
    );
};

export default Modal;