import React, { useEffect, useMemo, useState, useCallback } from 'react';
import Select from 'react-select';
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import DropdownButton from '../../buttons/DropdownButton';
import DecoratedInput from '../../inputs/DecoratedInput';
import Table from '../../table/Table';
import Button from '../../buttons/Button';
import Tag from '../../utils/Tag';
import useApi from '../../../hooks/useApi';
import { getRestaurantPartners, getRestaurantOffers, deleteRestaurantOffer, deleteRestaurantPartner } from '../../../services/restaurantService';
import { getListOptions } from '../../../services/listService';
import { formatDate } from '../../../utils/formatting';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useModal from '../../../hooks/useModal';
import Modal from '../../utils/Modal';

export const RestaurantPartners = ({ tKey = 'dashboard.partners.' }) => {
  usePageTitle('restaurantPartners');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    request: getPartnersRequest,
  } = useApi(getRestaurantPartners);
  const {
    request: getOffersRequest,
  } = useApi(getRestaurantOffers);
  const {
    request: getOfferTypesRequest,
  } = useApi(getListOptions);
  const {
    request: deleteOfferRequest,
  } = useApi(deleteRestaurantOffer);
  const {
    request: deletePartnerRequest,
  } = useApi(deleteRestaurantPartner);
  const [query, setQuery] = useState('');
  const [selectedOfferType, setSelectedOfferType] = useState(null);
  const [uniqueOfferTypes, setUniqueOfferTypes] = useState([]);
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);

  const closeAndResetModal = useCallback(() => {
    closeModal();
  }, [closeModal]);

  const fetchRestaurantPartners = useCallback(async (restaurantId) => {
    await getPartnersRequest(restaurantId)
      .then((response) => {
        setPartners(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [getPartnersRequest, setPartners]);

  const fetchRestaurantOffers = useCallback(async (restaurantId) => {
    await getOffersRequest(restaurantId)
      .then((response) => {
        setOffers(response.data);        
      })
      .catch((error) => {
        console.error(error);
      });
  }, [getOffersRequest, setOffers]);
  
  const attemptDeleteOffer = useCallback(async (offer) => {
    await deleteOfferRequest(restaurantId, { offerId: offer.offerId })
      .then(() => {
        fetchRestaurantOffers(restaurantId);
        fetchRestaurantPartners(restaurantId);
        closeAndResetModal();
      })
      .catch((error) => {
        console.error(error);
      });
  }, [deleteOfferRequest, restaurantId, fetchRestaurantOffers, fetchRestaurantPartners, closeAndResetModal]);

  const handleDeleteOffer = useCallback((offer) => {
    setModalContent(<>
      <h5 className='mt-0'>{t(`offerView.delete.title`)}</h5>
      <p>{t(`offerView.delete.text`)}</p>     
      <div className='flex justify-end gap-2'>
        <Button variant='secondary' onClick={() => closeAndResetModal()}>{t(`offerView.delete.cancel`)}</Button>
        <Button variant='danger' onClick={() => attemptDeleteOffer(offer)}>{t(`offerView.delete.confirm`)}</Button>
      </div>                           
    </>);
    openModal();
  }, [t, closeAndResetModal, attemptDeleteOffer, openModal]);

  const attemptDeletePartner = useCallback(async (partner) => {
    await deletePartnerRequest(restaurantId, { companyId: partner.companyId })
      .then(() => {
        fetchRestaurantPartners(restaurantId);
        fetchRestaurantOffers(restaurantId);
        closeAndResetModal();
      })
      .catch((error) => {
        console.error(error);
      });
  }, [deletePartnerRequest, restaurantId, fetchRestaurantPartners, fetchRestaurantOffers, closeAndResetModal]);
  
  const handleDeletePartner = useCallback((partner) => {
    setModalContent(<>
      <h5 className='mt-0'>{t(`${tKey}partnerDelete.title`, { name: partner.name })}</h5>
      <p>{t(`${tKey}partnerDelete.text`, { name: partner.name })}</p>       
      <div className='flex justify-end gap-2'>
        <Button variant='secondary' onClick={() => closeAndResetModal()}>{t(`utils.common.cancel`)}</Button>
        <Button variant='danger' onClick={() => attemptDeletePartner(partner)}>{t(`utils.common.delete`)}</Button>
      </div>                           
    </>);
    openModal();
  }, [t, tKey, setModalContent, openModal, closeAndResetModal, attemptDeletePartner]);

  const handleEditOffer = useCallback((row) => {
    navigate(`${row.original.offerId}`);
  }, [navigate]);

  const partnersColumns = useMemo(() => [
    {
      accessor: 'imagePath',
      Cell: ({ value }) => value ? <img src={value} alt='partner' /> : 'No image',
    },
    {
      Header: t(`${tKey}name`),
      accessor: 'name',
    },
    {
      Header: t(`${tKey}offers`),
      accessor: 'restaurantOffers',
      Cell: ({ value }) => <div className='flex flex-wrap gap-1'>
        {value && value.map((offer, index) => (
          <Tag key={index} tKey='utils.offerTypes.' text={offer.offertype.label} />
        ))}
      </div>
    },
    {
      Header: t(`${tKey}createdAt`),
      accessor: 'createdAt',
      Cell: ({ value }) => formatDate(value, false),
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            {
              label: t(`${tKey}actions.delete`),
              onClick: () => handleDeletePartner(row.original),
            },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },    
  ], [handleDeletePartner, t, tKey]);
  
  const offersColumns = useMemo(() => [
    {
      Header: t(`${tKey}distance`),
      accessor: 'distance',
      Cell: ({ value }) => `${value} km`,
    },
    {
      Header: t(`${tKey}maximumSubscribers`),
      accessor: 'maximumSubscribers',
      Cell: ({ row }) => `${row.original.currentSubscribers || 0}/${row.original.maximumSubscribers}`
    },
    {
      Header: t(`${tKey}offertype`),
      accessor: data => data.offertype ? data.offertype.label : '',
      Cell: ({ row }) => {
        return row.original.offertype.requiresValue 
          ? <>
            <div>{t(`utils.offerTypes.${row.original.offertype.label}`)}</div>
            <div className='p3 text-light'>{row.original.value}%</div>
          </>
          : t(`utils.offerTypes.${row.original.offertype.label}`);
      },
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            {
              label: t(`${tKey}actions.edit`),
              onClick: () => handleEditOffer(row),
            },
            {
              label: t(`${tKey}actions.delete`),
              onClick: () => handleDeleteOffer(row.original),
            },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [handleDeleteOffer, handleEditOffer, t, tKey]);

  const fetchOfferTypes = useCallback(async () => {
    await getOfferTypesRequest('offertype')
      .then((response) => {
        const offerTypes = response.data.map((type) => ({
          label: t(`utils.offerTypes.${type.label}`),
          value: type.offerTypeId,
        }));
        setUniqueOfferTypes(offerTypes);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [getOfferTypesRequest, setUniqueOfferTypes, t]);

  const filterPartnersAndOffers = useCallback((query) => {
    let filteredPartners = [...partners];
    let filteredOffers = [...offers];
    if(partners.length !== 0 && !!query)
      filteredPartners = filteredPartners.filter((partner) => partner.name.toLowerCase().includes(query.toLowerCase()) )
    if(offers.length !== 0 && !!selectedOfferType)
      filteredOffers = filteredOffers.filter((offer) => offer.offerTypeId === selectedOfferType.value)
    return { filteredPartners, filteredOffers };
  }, [partners, offers, selectedOfferType]);

  const goToAdd = () => {
    navigate('add');
  };

  useEffect(() => {
    fetchRestaurantPartners(restaurantId);
    fetchRestaurantOffers(restaurantId);
    fetchOfferTypes();
  }, [restaurantId, fetchRestaurantPartners, fetchRestaurantOffers, fetchOfferTypes]);

  useEffect(() => {
    const { filteredPartners, filteredOffers } = filterPartnersAndOffers(query);
    setFilteredPartners(filteredPartners);
    setFilteredOffers(filteredOffers);
  }, [selectedOfferType, partners, offers, query, filterPartnersAndOffers]);

  return (<>
    <div className='dashboard-content w-100 flex gap-2'>
      <div className='w-50 bg-white p-3 rounded flex column'>
        <div className='flex justify-between align-center w-100 my-1'>
          <p className='p1 my-0 ml-3'>{t(`${tKey}offers`)}</p>
          <div className='flex gap-2'>             
            <Select
              className='react-custom-select'
              classNamePrefix="react-select"
              placeholder={t(`${tKey}offertype`)}
              options={uniqueOfferTypes}
              value={selectedOfferType}
              isClearable
              isSearchable={false}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              onChange={(value) => setSelectedOfferType(value)}
            />
            <Button variant='primary' size='sm' onClick={goToAdd}>
              <FontAwesomeIcon icon={faPlus} />
              <span className='ml-2'>{t(`${tKey}addOffer`)}</span>
            </Button>
          </div>
        </div>
        <Table rowClassName='text-left' columns={offersColumns} data={filteredOffers} onClickRow={handleEditOffer}>
        </Table>
      </div>    
      <div className='w-50 bg-white p-3 rounded flex column gap-1'>
        <div className='flex justify-between align-center w-100 my-1'>
          <p className='p1 my-0 mb-0 ml-3'>{t(`${tKey}partners`)}</p>
          <DecoratedInput className='bg-pastel-light pl-2 dashboard-search-bar w-50' input={{ id: 'searchPartner', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: partners ? partners.length : 0 }) }}
            hasResearchButton
            onResearchButtonClick={ (query) => { setQuery(query) } }
          />
        </div>
        <Table rowClassName='text-left' firstColLeft columns={partnersColumns} data={filteredPartners}>
        </Table>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeAndResetModal} noFooter>
      {modalContent}
    </Modal>
  </>);
};

export default RestaurantPartners;