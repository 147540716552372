import { getRequest, postRequest, putRequest, deleteRequest } from '../api/methodsCfg';

export const getMenu = async (menuId) => {
    const response = await getRequest(`/menu/${menuId}`);
    return response;
};

export const getMenus = async (restaurantId) => {
    const response = await getRequest(`/menu/byRestaurant/${restaurantId}`);
    return response;
};

export const deleteMenu = async (menuId) => {
    const response = await deleteRequest(`/menu/${menuId}`);
    return response;
};

export const updateMenusOrder = async (restaurantId, ids) => {
    const response = await putRequest(`/menu/order/${restaurantId}`, { ids });
    return response;
};

export const creatNewMenu = async (payload) => {
    const cleanPayload = {
        name: payload.name,
        description: payload.description,
        isActive: payload.isActive || false,
        isPublished: payload.isPublished || false,
        price: payload.price,
        discount: payload.discount,
        dishIds: [payload.main.value.dishId, payload.starter?.value?.dishId, payload.dessert?.value?.dishId, payload.beverage?.value?.dishId].filter(id => id != null),
        restaurantId: payload.restaurantId,
    };
    const response = await postRequest(`/menu`, cleanPayload);
    return response;
};

export const duplicateMenu = async (payload) => {
    delete payload.menuId;
    payload.isActive = false;
    payload.isPublished = false;
    payload.dishIds = payload.dishes.map(dish => dish.dishId);
    const response = await postRequest(`/menu`, payload);
    return response;
};

export const updateMenu = async (payload) => {
    const cleanPayload = {
        name: payload.name,
        description: payload.description,
        isActive: payload.isActive || false,
        isPublished: payload.isPublished || false,
        price: payload.price,
        discount: payload.discount,
        dishIds: [payload.main.value.dishId, payload.starter?.value?.dishId, payload.dessert?.value?.dishId, payload.beverage?.value?.dishId].filter(id => id != null),
        restaurantId: payload.restaurantId,
        order: payload.order
    };
    const response = await putRequest(`/menu/${payload.menuId}`, cleanPayload);
    return response;
};

export const switchActivateMenu = async (menuId) => {
    const response = await putRequest(`/menu/active/${menuId}`);
    return response;
};