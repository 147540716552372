import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useApi from '../../hooks/useApi';
import { postConfirmAccount } from '../../services/authService';

function ConfirmAccount() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { activationCode } = useParams();
    const {
        status,
        request: confirmAccountRequest,
    } = useApi(postConfirmAccount);

    useEffect(() => {
        async function confirmAccount() {
            await confirmAccountRequest(activationCode)
                .then(() => {                
                    toast.success(t('confirmation.success'));
                    setTimeout(() => {
                        navigate('login');
                    }, 5000);                                
                })
                .catch(() => {
                    toast.error(t('confirmation.error'));
                })
        }
        confirmAccount();
    }, [activationCode, confirmAccountRequest, navigate, t]);

    return (
        <div id='login'>
            <div className='login-illustration' />
            <div className='login-content'>
                <h4>{t('confirmation.title')}</h4>
                {status === 200 && <h5>{t('confirmation.successTitle')}</h5>}
                {status >= 300 && <h5>{t('confirmation.errorTitle')}</h5>}
            </div>
        </div>
    );
}

export default ConfirmAccount;