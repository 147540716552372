import api from './axiosCfg';

class ApiResponseError extends Error {
  constructor(message, status) {
    super(message);
    this.status = status;
    this.name = 'ApiResponseError';
  }
};

const handleResponse = async (promise) => {
  try {
    const response = await promise;
    return { data: response.data, status: response.status };
  } catch (error) {
    const errResponse = error.response || {};
    throw new ApiResponseError(
      errResponse.data?.message || 'No response from the server',
      errResponse.status || 500
    );
  }
};

const handleWithFiles = (payload) => {
  const data = new FormData();
  Object.keys(payload).forEach((key) => {
    if (payload[key] instanceof File) {
      data.append(key, payload[key]);
    } else if (Array.isArray(payload[key])) {
      payload[key].forEach((item, index) => {
        if (item instanceof File) {
          data.append(`${key}[${index}]`, item);
        } else {
          data.append(`${key}[${index}]`, JSON.stringify(item));
        }
      });
    } else {
      data.append(key, payload[key]);
    }
  });
  return { data, headers: { 'Content-Type': 'multipart/form-data' } };
};

export const getRequest = (url, params) => {
  if (!url) throw new Error('URL is required');
  return handleResponse(api.get(url, { params }));
};

export const postRequest = (url, payload, withFiles = false) => {
  if (!url) throw new Error('URL is required');
  let { data, headers } = withFiles ? handleWithFiles(payload) : { data: payload, headers: {} };
  return handleResponse(api.post(url, data, { headers }));
};

export const putRequest = (url, payload, withFiles = false) => {
  if (!url) throw new Error('URL is required');
  let { data, headers } = withFiles ? handleWithFiles(payload) : { data: payload, headers: {} };
  return handleResponse(api.put(url, data, { headers }));
};

export const deleteRequest = (url, payload) => {
  if (!url) throw new Error('URL is required');
  return handleResponse(api.delete(url, { data: payload }));
};
