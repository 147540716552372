import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const SidebarItem = ({ item, isClickable, count = null, className='' }) => {
    const { t } = useTranslation();
    return (
        <div className={`p2 sidebar-item ${item.isActive ? 'active' : ''} ${className}`}>
            {!isClickable && 
                <span>
                    {`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}`}    
                </span>
            }
            {isClickable && item.onClick ?
                <NavLink onClick={item.onClick} className="sidebar-link">
                    {`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}` }
                </NavLink>
            : (isClickable && item.href !== null && item.href !== undefined &&
                    <NavLink to={isClickable ? item.href : '#'} className="sidebar-link">
                        {`${!!count ? `${count}. ` : ''}${t(`${item.tKey}sidebar`)}`}
                    </NavLink>
                )
            }   
        </div>
    );
};

export default SidebarItem;