let socket;
let heartbeatInterval;
const heartbeatFrequency = 1750000;

export const connectWebSocket = (url) => {
    socket = new WebSocket(url);
    socket.onopen = () => {
        console.log('WebSocket connection established');
        startHeartbeat();
    };
    socket.onclose = () => {
        console.log('WebSocket connection closed');
        stopHeartbeat();
    };
    socket.onerror = (error) => {
        console.error('WebSocket error: ', error);
    };
};

const startHeartbeat = () => {
    heartbeatInterval = setInterval(() => {
        if (socket.readyState === WebSocket.OPEN) socket.send(JSON.stringify({ action: 'heartbeat' }));
    }, heartbeatFrequency);
};

const stopHeartbeat = () => {
    if(heartbeatInterval) {
        clearInterval(heartbeatInterval);
        heartbeatInterval = null;
    };
}

export const subscribeToNotifications = (userId) => {
    if (socket.readyState === WebSocket.OPEN) socket.send(JSON.stringify({ action: 'subscribe', userId }));
    else socket.onopen = () => {
            socket.send(JSON.stringify({ action: 'subscribe', userId }));
        };
};

export const onMessage = (callback) => {
    socket.onmessage = (event) => {
        const message = JSON.parse(event.data);
        callback(message);
    };
};

export const closeWebSocket = () => {
    if (socket) {
        stopHeartbeat();
        socket.close()
    }
};