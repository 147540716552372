import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import useApi from '../../../../hooks/useApi';
import useModal from '../../../../hooks/useModal';
import { getRestaurantUsers, closeRestaurantManagerAccount } from '../../../../services/userService';
import { putTransferMangerRole, deleteRestaurant } from '../../../../services/restaurantService';
import { useAuth } from '../../../../hooks/useAuth';
import Alert from '../../../utils/Alert';
import Button from '../../../buttons/Button';
import Modal from '../../../utils/Modal';
import DropdownIndicator from '../../../inputs/DropdownIndicator';

const DangerZone = ({ tKey = 'dashboard.settings.dangerZone.' }) => {
    const { t } = useTranslation();
    const [employees, setEmployees] = useState([]);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [modalContent, setModalContent] = useState(null);
    const { openModal, closeModal, isModalOpen } = useModal();
    const { logout } = useAuth();
    const {
        request: getUsersRequest,
    } = useApi(getRestaurantUsers);
    const {
        request: putTransferManagerRoleRequest,
    } = useApi(putTransferMangerRole);
    const {
        request: deleteRestaurantRequest,
    } = useApi(deleteRestaurant);
    const {
        request: closeRestaurantManagerAccountRequest,
    } = useApi(closeRestaurantManagerAccount);
    const user = useSelector((state) => state.user);

    useEffect(() => {
        getUsersRequest(user.restaurantId).then((response) => {
            setEmployees(response.data.filter((employee) => employee.userId !== user.userId));
        }).catch((error) => {
            console.log(error);
            setEmployees([]);
        });
    }, [user.restaurantId, user.userId, getUsersRequest]);

    const handleTransferManagerRole = async () => {
        setModalContent(<>
            <Select components={{ DropdownIndicator }}
                className={`flex react-custom-select`}
                classNamePrefix="react-select"
                value={selectedEmployee} 
                onChange={(selected) => setSelectedEmployee(selected)}
                options={employees.map((employee) => ({
                    value: employee,
                    label: `${employee.firstname} ${employee.lastname}`,
                }))}
                placeholder={t(`${tKey}transferManager.placeholder`)}                
            />
            <p className='text-light p3'>{ t(`${tKey}transferManager.info`) }</p>
            <Button className='mt-5' variant='primary' onClick={attemptTransferManagerRole}>
                { t(`${tKey}transferManager.lastButton`) }
            </Button> 
        </>);
        openModal();
    };

    const attemptTransferManagerRole = async () => {
        if(!selectedEmployee) return;
        await putTransferManagerRoleRequest(user.restaurantId, { newManagerId: selectedEmployee.value.userId, oldManagerId: user.userId})
            .then((response) => {
                console.log(response);
                closeModal();
                logout();
            }).catch((error) => {
                toast(t(`${tKey}transferManager.error`), 'error');
                console.log(error);
            });
    };

    const handleCloseRestaurant = () => {
        setModalContent(<>
            <Button block variant='primary' onClick={attemptCloseRestaurant}>
                { t(`${tKey}restaurantDeletion.lastButton`) }
            </Button> 
        </>);
        openModal();
    };

    const attemptCloseRestaurant = async () => {
        await deleteRestaurantRequest(user.restaurantId).then((response) => {
            closeModal();
            logout();
        }).catch((error) => {
            toast(t(`${tKey}restaurantDeletion.error`), 'error');
            console.log(error);
        });
    };

    const handleCloseAccount = () => {
        setModalContent(<>
            <Select components={{ DropdownIndicator }}
                className={`flex react-custom-select`}
                classNamePrefix="react-select"
                value={selectedEmployee} 
                onChange={(selected) => setSelectedEmployee(selected)}
                options={employees.map((employee) => ({
                    value: employee,
                    label: `${employee.firstname} ${employee.lastname}`,
                }))}
                placeholder={t(`${tKey}accountDeletion.placeholder`)}                
            />
            <p className='text-light p3'>{ t(`${tKey}accountDeletion.info`) }</p>
            <Button className='mt-5' variant='primary' onClick={attemptCloseAccount}>
                { t(`${tKey}accountDeletion.lastButton`) }
            </Button> 
        </>);
        openModal();
    };

    const attemptCloseAccount = async () => {
        if(!selectedEmployee) return;
        await closeRestaurantManagerAccountRequest(user.restaurantId, { userId: selectedEmployee.value.userId })
            .then((response) => {
                closeModal();
                logout();
            }).catch((error) => {
                toast(t(`${tKey}accountDeletion.error`), 'error');
                console.log(error);
            });
    }


    return (<>
        <div className='dashboard-content flex gap-2'>
            <div className='w-100 bg-white rounded p-3 flex column gap-2'>
                <div>
                    <Alert variant='warning'>
                        <h4 className='my-2'>
                            <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
                            { t(`${tKey}transferManager.alert.title`) }
                        </h4>
                        <p>{ t(`${tKey}transferManager.alert.text`) }</p>
                        <Button variant='warning' className='mt-2' onClick={handleTransferManagerRole}>
                            { t(`${tKey}transferManager.button`) }
                        </Button>
                    </Alert>
                </div>
                <div>
                    <Alert variant='danger'>
                        <h4 className='my-2'>
                            <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
                            { t(`${tKey}restaurantDeletion.alert.title`) }
                        </h4>
                        <p>{ t(`${tKey}restaurantDeletion.alert.text`) }</p>
                        <Button variant='danger' className='mt-2' onClick={handleCloseRestaurant}>
                            { t(`${tKey}restaurantDeletion.button`) }
                        </Button>
                    </Alert>
                </div>
                <div>
                    <Alert variant='danger'>
                        <h4 className='my-2'>
                            <FontAwesomeIcon icon={faExclamationTriangle} className='mr-2' />
                            { t(`${tKey}accountDeletion.alert.title`) }
                        </h4>
                        <p>{ t(`${tKey}accountDeletion.alert.text`) }</p>
                        <Button variant='danger' className='mt-2' onClick={handleCloseAccount}>
                            { t(`${tKey}accountDeletion.button`) }
                        </Button>
                    </Alert>
                </div>                              
            </div>
        </div>
        <Modal isOpen={isModalOpen} onClose={closeModal} noFooter>
            { modalContent }
        </Modal>
    </>);
};

export default DangerZone;
