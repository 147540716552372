import React from 'react';
import { useTranslation } from 'react-i18next';

function EmptyForm() {
  const { t } = useTranslation();  
  return (
    <>
     <p className='bold'>{t('utils.errors.emptyForm.title')}</p> 
     <p className='p3'>{t('utils.errors.emptyForm.text')}</p>
    </>
  );
}

export default EmptyForm;