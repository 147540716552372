import React, { useState, useEffect, useMemo, useContext, useCallback } from 'react';
import { usePageTitle } from '../../../hooks/useMeta';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { updateUser } from '../../../redux/slices/userSlice';
import { toast } from 'react-toastify';
import useApi from '../../../hooks/useApi';
import Tabs from '../../tabs/Tabs';
import Profile from '../settings/company/Profile';
import Images from '../settings/company/Images';
import Manager from '../settings/company/Manager';
import Connection from '../settings/Connection';
import DangerZone from '../settings/company/DangerZone';
import { getCompanySettings, updateCompanySettings } from '../../../services/companyService';
import { getCompanyUserDetail, updateCompanyEmployee } from '../../../services/userService';
import { putAttemptChangePassword } from '../../../services/authService';
import SettingsContext from '../../../contexts/SettingsContext';

export const CompanySettings = ({ tKey = 'dashboard.settings.' }) => {
  usePageTitle('companySettings');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [companySettings, setCompanySettings] = useState({});
  const [tempManagerSettings, setTempManagerSettings] = useState({});
  const [tempCompanySettings, setTempCompanySettings] = useState({});
  const [tempPassword, setTempPassword] = useState({});
  const {
    request: getSettingsRequest,
  } = useApi(getCompanySettings);
  const {
    request: getManagerRequest,
  } = useApi(getCompanyUserDetail);
  const {
    request: updateSettingsRequest,
  } = useApi(updateCompanySettings);
  const {
    request: updateManagerRequest,
  } = useApi(updateCompanyEmployee);
  const {
    request: attemptChangePasswordRequest,
  } = useApi(putAttemptChangePassword);
  const user = useSelector((state) => state.user);
  const { activeTab, setActiveTab, setIsValid, setApplyChanges, setHideSettingsButton } = useContext(SettingsContext);

  const handleFormDataChange = useCallback((data) => {
    setIsValid(!!data);
    setTempCompanySettings((old) => { return { ...old, ...data } });
  }, [setIsValid, setTempCompanySettings]);

  const handleManagerDataChange = useCallback((data) => {
    setIsValid(!!data);
    setTempManagerSettings((old) => { return { ...old, ...data } });
  }, [setIsValid, setTempManagerSettings]);

  const handlePasswordChange = useCallback((data) => {
    setIsValid(!!data);
    setTempPassword(data);
  }, [setIsValid, setTempPassword]);

  const tabs = useMemo(() => [
    {
      name: 'profile',
      content: () => <Profile data={companySettings} onFormDataChange={handleFormDataChange} />,
    },
    {
      name: 'images',
      content: () => <Images data={companySettings} onFormDataChange={handleFormDataChange} />,
    },
    {
      name: 'manager',
      content: () => <Manager data={user} onFormDataChange={handleManagerDataChange} />,
    },
    {
      name: 'connection',
      content: () => <Connection onFormDataChange={handlePasswordChange} />,
    },
    {
      name: 'dangerZone',
      content: () => <DangerZone />,
      buttonVariant: 'danger',
    },
  ], [companySettings, handleFormDataChange, handleManagerDataChange, handlePasswordChange, user]);

  const fetchSettingsCompany = useCallback(async (companyId) => {
    await getSettingsRequest(companyId)
      .then((response) => {     
        setCompanySettings(response.data);        
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getSettingsRequest, setCompanySettings]);

  const fetchSettingsManager = useCallback(async (userId) => {
    await getManagerRequest(userId)
      .then((response) => {
        dispatch(updateUser(response.data));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getManagerRequest, dispatch]);

  const attemptUpdateProfile = useCallback(async () => {
    await updateSettingsRequest(user.companyId, tempCompanySettings).then(async () => {
      toast.success(t(`${tKey}success`));
      await fetchSettingsCompany(user.companyId);
    });
  }, [updateSettingsRequest, user.companyId, tempCompanySettings, t, tKey, fetchSettingsCompany]);

  const attemptUpdateManager = useCallback(async () => {
    await updateManagerRequest(user.userId, tempManagerSettings).then(async () => {
      toast.success(t(`${tKey}success`));
      await fetchSettingsManager(user.userId);
    });
  }, [updateManagerRequest, user.userId, tempManagerSettings, t, tKey, fetchSettingsManager]);

  const attemptChangePassword = useCallback(async () => {
    await attemptChangePasswordRequest(user.userId, tempPassword).then(() => {
      toast.success(t(`${tKey}success`));
    });
  }, [attemptChangePasswordRequest, user.userId, tempPassword, t, tKey]);

  const applyChanges = useCallback(() => {
    switch (activeTab) {
      case 'manager':
        return attemptUpdateManager();
      case 'connection':
        return attemptChangePassword();
      case 'dangerZone':
        return;
      default:
        return attemptUpdateProfile();
    };
  }, [activeTab, attemptUpdateProfile, attemptUpdateManager, attemptChangePassword]);

  const setActiveTabWrapper = (tab) => {
    setHideSettingsButton(tab === 'dangerZone');
    setActiveTab(tab);
  };

  useEffect(() => {
    return () => {
      setActiveTab('');
    };
  }, [setActiveTab]);

  useEffect(() => {
    fetchSettingsCompany(user.companyId);
  }, [user.companyId, fetchSettingsCompany]);

  useEffect(() => {
    setApplyChanges(() => applyChanges);
  }, [applyChanges, setApplyChanges]);

  return (
    <div className='dashboard-content'>
      <Tabs tKey={tKey} tKeySuffix='.tab' tabs={tabs} onTabChange={setActiveTabWrapper} contentClass='flex gap-1 h-90'>
      </Tabs>
    </div>
  );
};

export default CompanySettings;