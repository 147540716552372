import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';

const Spinner = ({ label, size = '2x', variant = 'primary' }) => {
    const variantClass = `text-${variant}`
    return (
        <div className="w-100 text-center my-5">
            <FontAwesomeIcon className={variantClass} icon={faSpinner} spin size={size} />
            {label && <div className='mt-2 text-light'>{label}</div>}
        </div>
    );
};

export default Spinner;