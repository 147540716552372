import React, { useMemo } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import Table from '../../../table/Table';
import Point from '../../../utils/Point';
// import SaleDetails from './SaleDetails';
import { formatDate, formatMoney } from '../../../../utils/formatting';

const Sales = ({ data, filteredData, tKey = 'dashboard.sales.' }) => {
    const { t } = useTranslation();
    // const [selectedOrder, setSelectedOrder] = useState(null);
    const columns = [
        {
            Header: t(`${tKey}id`),
            accessor: 'orderId',
        },
        {
            Header: t(`${tKey}price`),
            accessor: 'totalPrice',
            Cell: ({ value }) => {
                return <>{formatMoney(value)}</>;
            },
        },
        {
            Header: t(`${tKey}date`),
            accessor: 'startAt',
            Cell: ({ row }) => {
                const { startAt } = row.original;
                return formatDate(startAt);
            },
        },
        {
            Header: t(`${tKey}status`),
            Cell: ({ row }) => {
                const { outcome } = row.original;
                const status = outcome.orderstatus;
                return <Point variant={status.variant} />;
            },
        },
    ];

    const totalSales = useMemo(() => {
        if (data) return data.reduce((acc, item) => {
            if(item.outcome.orderStatusId !== 5) return acc; // 5 is the status for successfully done orders
            return acc + item.totalPrice
        }, 0);
    }, [data])

    const chartData = useMemo(() => {
        if(!data) return;
        const groupedByDate = data.reduce((acc, item) => {
            if(item.outcome.orderStatusId !== 5) return acc; // 5 is the status for successfully done orders
            const date = item.startAt.split('T')[0];
            acc[date] = (acc[date] || 0) + formatMoney(item.totalPrice);
            return acc;
        }, {});
    
        return {
            categories: Object.keys(groupedByDate),
            series: Object.values(groupedByDate),
        };
    }, [data]);

    const chartOptions = {
        chart: {
            type: 'bar',
            width: '100%',
            toolbar: {
                show: false,
            },
        },
        xaxis: {
            categories: chartData?.categories,
        },
        plotOptions: {
            bar: {
                colors: {
                    ranges: [
                        {
                            from: 0,
                            to: 10000,
                            color: '#1AE170'
                        }
                    ]
                }
            }
        },
    };

    return (
        <>
            <div className='w-50 bg-white rounded flex column gap-3'>
                <div className='table-container'>
                    {/*<Table columns={columns} data={data} onClickRow={setSelectedOrder} />*/}
                    <Table columns={columns} data={filteredData} />
                </div>
            </div>
            <div className='w-50 bg-white p-3 rounded'>
                <div className='table-container pr-3'>
                {/*{selectedOrder 
                    ? <SaleDetails tKey={tKey} row={selectedOrder} removeSelected={() => setSelectedOrder(null)} />
                    : <div className='text-left'>
                        <div className='p1'>{`${t(`${tKey}total`)}: ${formatMoney(totalSales)}`}</div>
                        {chartData && (
                            <ReactApexChart
                                options={chartOptions}
                                series={[{ name: t(`${tKey}total`) ,data: chartData.series }]}
                                type='bar'
                            />
                        )}
                    </div>
                }
                */}
                    <div className='text-left'>
                        <div className='p1'>{`${t(`${tKey}total`)}: ${formatMoney(totalSales)}`}</div>
                        {chartData && (
                            <ReactApexChart
                                options={chartOptions}
                                series={[{ name: t(`${tKey}total`), data: chartData.series }]}
                                type='bar'
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default Sales;
