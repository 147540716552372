import React from 'react';
import { formatDate } from '../../../../utils/formatting';
import { useTranslation } from 'react-i18next';

const PartnerCard = ({ partner }) => {
    const { t } = useTranslation();
    return (
        <div className='partner-card'>
            {partner.imagePath && <img src={partner.imagePath} alt={partner.name} height={50} />}
            <div className='partner-card-content'>
                <p className='bold'>{partner.name}</p>     
                <p className='p4 text-light'>{`${t('utils.common.since')} ${formatDate(partner.partnership.createdAt, false)}`}</p> 
            </div>
        </div>
    );
};

export default PartnerCard;
