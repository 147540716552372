import React from "react";

function OffoodLogo({ size = 'md', width, height = 'auto', variant = 'black', onClick = () => {} }) {
    const baseWidth = 120;
    const setWidth = (size, width) => {
        if(width)
            return width;
        switch(size) {
            case 'sm':
                return baseWidth * 0.33;
            case 'lg':
                return baseWidth * 2;
            default:
                return baseWidth;
        }        
    }
    const setVariant = variant => {
        switch (variant) {
            case 'black':
                return 'black';
            case 'white':
                return 'white';
            case 'red':
                return 'red';
            default:
                return 'black';
        }
    }
    return (
        <img width={setWidth(size, width)} height={height} src={require(`../../assets/img/logo/${setVariant(variant)}.svg`)} alt={`${setVariant(variant)} offood logo`} onClick={onClick} />
    );
}

export default OffoodLogo;