import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import RestaurantAppPreview from '../../app/RestaurantAppPreview';
import Button from '../../buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-thin-svg-icons';

function StepThreeRestaurant({ currentForm, setRestaurantPicture, name, restaurantType, setIsValid = () => true }) {
  const { t } = useTranslation();
  const [img, setImg] = useState(null);

  useEffect(() => {
    setIsValid(true)
    setImg(currentForm.image ? URL.createObjectURL(currentForm.image) : null);
  }, [currentForm.image, setIsValid])

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      let image = e.target.files[0];
      setImg(URL.createObjectURL(image));
      setRestaurantPicture({'image': image});
    }
  };

  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.step3.title')}</h2>
        <div className='p3'>{t('register.step3.details')}</div>
      </div>
      <div className='step-app-preview-wrapper'>
        <RestaurantAppPreview title={name} restaurantType={restaurantType} img={img} /> 
        <div className='add-button'>
          <input 
            type="file" 
            accept="image/*" 
            onChange={handleImageChange} 
            style={{ display: 'none' }} 
            id="fileInput"
          />
          <Button variant='white' size='sm' onClick={() => document.getElementById('fileInput').click()}>
            <FontAwesomeIcon className='mr-1' icon={faPlus} />
            {t('register.step3.addPicture')}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default StepThreeRestaurant;