import React from "react";

export const AdminOverview = () => {
  return (
    <div>
      Admin overview
    </div>
  );
};
  
  export default AdminOverview;