import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { getListOptions } from '../../../services/listService';
import Form from '../../inputs/Form';
import Spinner from '../../utils/Spinner';

function StepCompany({ currentForm, setCompanyDetails, setIsValid = () => {} }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [citiesOptions, setCitiesOptions] = useState([]);
  const [languagesOptions, setLanguagesOptions] = useState([]);
  const [fields, setFields] = useState([]);
  
  useEffect(() => {
    const fetchCities = async () => {
      setIsLoading(true);
      try {
        const cities = await getListOptions('city');
        setCitiesOptions(cities.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    const fetchLanguages = async () => {
      setIsLoading(true);
      try {
        const languages = await getListOptions('language');
        setLanguagesOptions(languages.data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchCities();
    fetchLanguages();
  }, []);
  
  useEffect(() => {
    if(citiesOptions.length > 0 && languagesOptions.length > 0)
      setFields([
        {
          name: 'name',
          label: t('register.stepCompany.name.label'),
          placeholder: t('register.stepCompany.name.placeholder'),
          type: 'text',
          class: 'w-50 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.name.required');
            if (value.length < 3) return t('register.errors.name.min');
            if (value.length > 50) return t('register.errors.name.max');
            return '';
          },
        },
        {
          name: 'phone',
          label: t('register.stepCompany.phone.label'),
          placeholder: t('register.stepCompany.phone.placeholder'),
          type: 'phone',
          class: 'w-50 pl-2',
          validate: (value) => {
            if (!value) return t('register.errors.phone.required');
            if (!isValidPhoneNumber(value)) return t('register.errors.phone.invalid');
            return '';
          },
        },
        {
          name: 'email',
          label: t('register.stepCompany.email.label'),
          placeholder: t('register.stepCompany.email.placeholder'),
          type: 'email',
          class: 'w-50 pr-2',
          validate: (value) => {
            if (!value) return t('register.errors.email.required');
            if (!/\S+@\S+\.\S+/.test(value)) return t('register.errors.email.invalid');
            return '';
          },
        },
        {
          name: 'language',
          label: t('register.stepCompany.language.label'),
          placeholder: t('register.stepCompany.language.placeholder'),
          type: 'dropdown',
          class: 'w-50 pl-2',
          options: languagesOptions,
          validate: (value) => {
            if (!value) return t('register.errors.language.required');
            return '';
          },
          optionFormat: (value) => {
            return t(`utils.lang.${value.label}`)
          },
        },
        {
          name: 'address',
          label: t('register.stepCompany.address.label'),
          placeholder: t('register.stepCompany.address.placeholder'),
          type: 'text',
          class: 'w-50 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.address.required');
            if (value.length > 100) return t('register.errors.address.max');
            return '';
          },
        },
        {
          name: 'city',
          label: t('register.stepCompany.city.label'),
          placeholder: t('register.stepCompany.city.placeholder'),
          type: 'dropdown',
          class: 'w-50 pl-2',
          options: citiesOptions,
          validate: (value) => {
            if (!value) return t('register.errors.city.required');
            return '';
          },
          optionFormat: (value) => {
            return `${value.npa} ${value.label} `
          },
        },
        {
          name: 'firstname',
          label: t('register.stepCompany.firstname.label'),
          placeholder: t('register.stepCompany.firstname.placeholder'),
          type: 'text',
          class: 'w-50 pr-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.firstname.required');
            if (value.length < 3) return t('register.errors.firstname.min');
            if (value.length > 25) return t('register.errors.firstname.max');
            return '';
          },
        },
        {
          name: 'lastname',
          label: t('register.stepCompany.lastname.label'),
          placeholder: t('register.stepCompany.lastname.placeholder'),
          type: 'text',
          class: 'w-50 pl-2',
          validate: (value) => {
            if (!value.trim()) return t('register.errors.lastname.required');
            if (value.length < 3) return t('register.errors.lastname.min');
            if (value.length > 25) return t('register.errors.lastname.max');
            return '';
          },
        },
        {
          name: 'message',
          label: t('register.stepCompany.message.label'),
          placeholder: t('register.stepCompany.message.placeholder'),
          type: 'textarea',
          class: 'w-100',
          rows: 5,
          validate: (value) => {
            if (!value.trim()) return t('register.errors.message.required');
            if (value.length > 500) return t('register.errors.message.max');
            if (value.length < 10) return t('register.errors.message.min');
            return '';
          },
        }
      ]
    );
  }, [citiesOptions, t, languagesOptions]);

  const handleCompanyDetails = (data, isValid) => {
    setCompanyDetails(data);
  };

  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.stepCompany.title')}</h2>
        <div className='p3'>{t('register.stepCompany.details')}</div>
      </div>
      {isLoading
        ? <Spinner size='3x' variant='danger' label={t('utils.data.loading')} />
        : <Form fields={fields} currentForm={currentForm} setFormDetails={handleCompanyDetails} setIsValid={setIsValid} />
      }
    </div>
  );
}

export default StepCompany;