import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/pro-light-svg-icons';

const ProductAppPreview = ({ img = null, dishCategory = null, title = 'Test', subtitle = '... km', number = 10 }) => {
    const [imgPath] = useState('');

    useEffect(() => {
        if(!dishCategory)
            return;
        try {
            if(!img) return   
            /*             
                import(`../../assets/img/dishCategories/${dishCategory?.label}.svg`)
                    .then(image => setImgPath(image.default))
                    .catch(error => {
                        console.error("Failed to load image:", error);
                        toast.error("Failed to load image");
                    })
            */
        } catch (error) {
            console.error("Failed to load image:", error);
            toast.error("Failed to load image");
        }
      }, [img, dishCategory]);

    return (
        <div className='product-preview-card'>
            <div className={`product-preview-img bg-pastel-${dishCategory?.variant || 'light'}`}>
                {img 
                    ? <img className='custom-img' src={img} alt={dishCategory?.label || ''} /> 
                    : <img src={imgPath} alt={dishCategory?.label || ''} />}
            </div>
            <div className='product-preview-text'>
                <div className='w-80'>
                    <h4>{title}</h4>
                    <div className='p4'>{subtitle}</div>
                </div>
                <div className='p3'>
                    <FontAwesomeIcon icon={faStar} className='mr-1' />
                    <span>{number}</span>
                </div>
            </div>
        </div>
    );
};

export default ProductAppPreview;