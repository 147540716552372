import React from 'react';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import Button from '../buttons/Button';
import useLangNavigate from '../../hooks/useLangNavigate';

const Cookie = ({ tKey = 'utils.cookie.' }) => {
    const { t } = useTranslation();
    const navigate = useLangNavigate();

    const acceptOnlyRequiredCookies = () => {
        Cookies.set('userConsent', 'required', { expires: 150 });        
        Cookies.remove('analyticsCookie');
    };
    const acceptAllCookies = () => {
        Cookies.set('userConsent', 'all', { expires: 150 });
    };

    return (
        <CookieConsent
            location="bottom"
            cookieName={t(`${tKey}name`)}
            expires={150}
            buttonWrapperClasses='flex column gap-1 p-2 self-align-center'
            enableDeclineButton
            ButtonComponent={Button}        
            customButtonProps={{ variant: 'success', size: 'sm' }}  
            customDeclineButtonProps={{ variant: 'info', size: 'sm' }}
            onAccept={acceptAllCookies}
            onDecline={acceptOnlyRequiredCookies}
            buttonText={t(`${tKey}acceptAll`)}
            declineButtonText={t(`${tKey}acceptRequired`)}
        >
            {t(`${tKey}message`)}{" "}
            <Button link onClick={() => navigate('privacy-policy')}>
                {t(`${tKey}privacyPolicy`)}
            </Button>
        </CookieConsent>
      );
};

export default Cookie;
