import React, { useState } from 'react';
import Button from '../buttons/Button'; // make sure to import your Button component
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

function DecoratedInput({ className,
    input = { id: '', type: 'text', value: '', placeholder: 'placeholder...', disabled: false, onClick: () => { return } },
    hasResearchButton = false,
    onResearchButtonClick = () => {},
    tailBtn,
}) {
    const [inputValue, setInputValue] = useState(input.value);

    const handleResearchClick = () => {
        if(onResearchButtonClick)
            onResearchButtonClick(inputValue);
    };

    const handleKeyDown = (e) => {
        if(e.key === 'Enter')
            handleResearchClick();
    };

    return (
        <div className={`input-wrapper ${className}`}>
            {hasResearchButton && (
                <Button variant='white' size='xs' rounded={true} onClick={handleResearchClick}>
                    <FontAwesomeIcon icon={faSearch} />
                </Button>
            )}
            <input id={input.id}
                className={input.class}
                type={input.type}
                placeholder={input.placeholder}
                disabled={input.disabled}
                value={input.value?.name || inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                onKeyDown={handleKeyDown}
                onClick={input.onClick}
            />
            {tailBtn && <div className='input-tail'>
                {React.cloneElement(tailBtn, {
                    onClick: () => tailBtn.props.onClick(inputValue)
                })}
            </div>}
        </div>
    );
}

export default DecoratedInput;
