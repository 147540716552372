import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical, faEllipsisVertical, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import useModal from '../../../hooks/useModal';
import Button from '../../buttons/Button';
import DecoratedInput from '../../inputs/DecoratedInput';
import NoData from '../../errors/NoData';
import Spinner from '../../utils/Spinner';
import Table from '../../table/Table';
import DownloadTable from '../../table/DownloadTable';
import DropdownButton from '../../buttons/DropdownButton';
import Modal from '../../utils/Modal';
import { getCateringDishes, updateDishesOrder, duplicateDish, deleteDish } from '../../../services/cateringService';
import { usePageTitle } from '../../../hooks/useMeta';

export const RestaurantCatering = ({ tKey = 'dashboard.catering.' }) => {
  usePageTitle('restaurantCatering');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getDishesError,
    loading: getDishesLoading,
    request: getDishesRequest,
  } = useApi(getCateringDishes);
  const {
    request: deleteDishRequest,
  } = useApi(deleteDish);
  const {
    request: duplicateDishRequest,
  } = useApi(duplicateDish);
  const [query, setQuery] = useState('');
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [uniqueTypes, setUniqueTypes] = useState([]);
  const [selectedDish, setSelectedDish] = useState(null);
  const [dishes, setDishes] = useState([]);
  const [filteredDishes, setFilteredDishes] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);

  const fetchRestaurantDishes = useCallback(async (restaurantId) => {
    await getDishesRequest(restaurantId)
      .then((response) => {
        setDishes(response.data);
        const typeMap = new Map();
        response.data.forEach(dish => {
          if (dish.dishtype && !typeMap.has(dish.dishtype.dishTypeId))
            typeMap.set(dish.dishtype.dishTypeId, dish.dishtype);   
        });
        setUniqueTypes(Array.from(typeMap.values()).map(type => ({ value: type.dishTypeId, label: t(`utils.dishTypes.${type.label}`) })));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getDishesRequest, setDishes, setUniqueTypes, t]);

  const handleDetail = useCallback((row) => {
    navigate(`${row.original.dishId}`);
  }, [navigate]);

  const handleDuplicate = useCallback(async (dish) => {
    await duplicateDishRequest(dish)
      .then(() => {
        fetchRestaurantDishes(restaurantId);
        toast.success(t(`${tKey}duplicate.success`, { dishName: dish.name }));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}duplicate.error`, { dishName: dish.name }));
      })
  }, [duplicateDishRequest, fetchRestaurantDishes, restaurantId, t, tKey]);

  const handleDelete = useCallback((dish) => {
    setSelectedDish(dish);    
    setModalContent(<>
      <h5 className='mt-0'>{t(`${tKey}delete.title`)}</h5>
      <p>{t(`${tKey}delete.text`, { dishName: dish.name })}</p>
    </>);
    openModal();
  }, [setSelectedDish, setModalContent, t, tKey, openModal]);

  const columns = useMemo(() => [
    {
      Header: (<div>
        <FontAwesomeIcon
          icon={faInfoCircle}
          data-tooltip-id="infoTip"
          data-tooltip-html={t(`${tKey}order.info`)}
        />
        <Tooltip id="infoTip" place="bottom" effect="solid" />
      </div>),
      accessor: 'order',
      isDragHandle: true,
      Cell: () => <FontAwesomeIcon color='black' icon={faGripDotsVertical} />,      
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}image`),
      accessor: 'imagePath',
      Cell: ({ value }) => (value ? <img src={value} alt="Dish" style={{ width: '50px' }} /> : 'No image'),
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}title`),
      accessor: 'name',
    },
    {
      Header: t(`${tKey}type.header`),
      accessor: data => data.dishtype ? data.dishtype.label : 'No type',
      Cell: ({ value }) => t(`${tKey}type.${value}`),
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}price`),
      accessor: 'price',
      Cell: ({ row }) => {
        const { price, discount } = row.original;
        return discount 
          ? <>
            <div>{(price - price * discount / 100).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}</div>
            <div className='strikethrough text-light p4 mt-1'>{price.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}</div>
          </>
          : price.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
      },      
    },
    {
      Header: t(`${tKey}status.header`),
      Cell: ({ row }) => (
        <div className={`rounded text-white py-1 px-3 text-center fit-content ${!row.original.isPublished ? 'bg-dark' : row.original.isActive ? 'bg-success' : 'bg-danger'}`}>{t(`${tKey}status.${!row.original.isPublished ? 'unpublished' : row.original.isActive ? 'active' : 'inactive'}`)}</div> 
      ),
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            { label: t(`${tKey}actions.edit`), onClick: () => handleDetail(row) },
            { label: t(`${tKey}actions.duplicate`), onClick: () => handleDuplicate(row.original) },
            { label: t(`${tKey}actions.delete`), onClick: () => handleDelete(row.original) },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [handleDelete, handleDetail, handleDuplicate, t, tKey]);

  const statusOptions = useMemo(() => [
    { value: 'active', label: t(`${tKey}status.active`) },
    { value: 'inactive', label: t(`${tKey}status.inactive`) },
    { value: 'unpublished', label: t(`${tKey}status.unpublished`) },
  ], [t, tKey]);

  const goToAdd = () => {
    navigate('add');
  };

  const handleOrderChange = async (newOrder) => {
    await updateDishesOrder(restaurantId, newOrder.map(dish => dish.dishId))
      .then((res) => {
        setDishes(res.data);
        toast.success(t(`${tKey}order.success`));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}order.error`));
      });
  };

  const deleteSelectedDish = async () => {
    await deleteDishRequest(selectedDish.dishId)
    .then((res) => {
      if (res.status === 200) {
        setDishes(dishes.filter(d => d.dishId !== selectedDish.dishId));
        toast.success(t(`${tKey}delete.success`, { dishName: selectedDish.name }));
      };
    })
    .catch((err) => {
      console.error(err);
      toast.error(t(`${tKey}delete.error`, { dishName: selectedDish.name }));
    })
    .finally(async () => {
      setModalContent(null);
      setSelectedDish(null);
      closeModal();
      await fetchRestaurantDishes(restaurantId);
    });
  };

  const filterDishes = useCallback((query) => {
    if(!dishes) return [];
    let filteredDishes = [...dishes];
    if (selectedType)
      filteredDishes = filteredDishes.filter(d => d.typeId === selectedType.value);
    if (selectedStatus)
      switch(selectedStatus.value) {
        case 'active':
          filteredDishes = filteredDishes.filter(d => d.isPublished && d.isActive);
          break;
        case 'inactive':
          filteredDishes = filteredDishes.filter(d => d.isPublished && !d.isActive);
          break;
        case 'unpublished':
          filteredDishes = filteredDishes.filter(d => !d.isPublished);
          break;
        default:
          break;
      }
    if (query) filteredDishes = filteredDishes.filter(d => d.name.toLowerCase().includes(query.toLowerCase()));
    return filteredDishes;
  }, [dishes, selectedType, selectedStatus]);

  useEffect(() => {
    fetchRestaurantDishes(restaurantId);
  }, [restaurantId, fetchRestaurantDishes]);

  useEffect(() => {
    const filteredDishes = filterDishes(query);
    setFilteredDishes(filteredDishes);
  }, [query, dishes, selectedType, selectedStatus, filterDishes]);

  if (getDishesError) return <div>Error: {getDishesError}</div>;
  if (getDishesLoading) return <Spinner />;

  return (
    <div className='dashboard-content'>
      <div className='flex gap-1'>
        <Button variant='primary' size='sm' onClick={goToAdd}>
          <FontAwesomeIcon icon={faPlus} />
            <span className='ml-2'>{t(`${tKey}addProduct`)}</span>
        </Button>
        <div className='ml-auto flex gap-1 w-80'>
          <DecoratedInput className='bg-white dashboard-search-bar w-100' input={{ id: 'searchCatering', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: dishes ? dishes.length : 0 }) }}
            hasResearchButton
            onResearchButtonClick={ (query) => { setQuery(query) } }
          />
          <Select
            className='react-custom-select dashboard-select'
            classNamePrefix="react-select"
            placeholder={t(`${tKey}type.header`)}
            options={uniqueTypes}
            value={selectedType}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedType(value)}
          />
          <Select
            className='react-custom-select dashboard-select'
            classNamePrefix="react-select"
            placeholder={t(`${tKey}status.header`)}
            options={statusOptions}
            value={selectedStatus}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedStatus(value)}
          />
          <DownloadTable data={filteredDishes} fileName='dishes'  fileTypes={['csv', 'xlsx']} />
        </div>
      </div>
      <div className='mt-4 table-container'>
        {filteredDishes?.length === 0 ? <NoData />
          : <Table columns={columns} data={filteredDishes} isDroppable={!!selectedType} onOrderChange={handleOrderChange} onClickRow={handleDetail} />}          
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteSelectedDish}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default RestaurantCatering;