import React from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import { SettingsProvider } from '../../contexts/SettingsContext';
import { NavigationProvider } from '../../contexts/NavigationContext';

const DashboardLayout = ({ menuList, navigatePath, children }) => {
  return (
    <SettingsProvider>
      <NavigationProvider>
        <div className='sidebar-page-wrapper'>
          <Sidebar menuList={menuList} isClickable />
          <div id='dashboard'>
            <Navbar navigateBack={navigatePath} />
            <>
              { children }
            </>
          </div>
        </div>
      </NavigationProvider>
    </SettingsProvider>
  );
};

export default DashboardLayout;