import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { usePageTitle } from '../../../hooks/useMeta';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useModal from '../../../hooks/useModal';
import useApi from '../../../hooks/useApi';
import Button from '../../buttons/Button';
import DecoratedInput from '../../inputs/DecoratedInput';
import Spinner from '../../utils/Spinner';
import NoData from '../../errors/NoData';
import Table from '../../table/Table';
import Modal from '../../utils/Modal';
import UserAvatar from '../../utils/UserAvatar';
import { getCompanyUsers, getCompanyUserDetail, acceptCompanyEmployee, removeCompanyEmployee } from '../../../services/userService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faTrash } from '@fortawesome/pro-regular-svg-icons';

export const CompanyEmployees = ({ tKey = 'dashboard.employees.' }) => {
  usePageTitle('companyEmployees');
  const { t } = useTranslation();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getEmployeesError,
    loading: getEmployeesLoading,
    request: getEmployeesRequest,
  } = useApi(getCompanyUsers);
  const {
    request: getEmployeeDetail,
  } = useApi(getCompanyUserDetail);
  const {
    request: acceptEmployee,
  } = useApi(acceptCompanyEmployee);
  const {
    request: removeEmployee,
  } = useApi(removeCompanyEmployee);
  const [query, setQuery] = useState('');
  const [employees, setEmployees] = useState([]);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredPendingEmployees, setFilteredPendingEmployees] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const user = useSelector((state) => state.user);

  const fetchCompanyEmployees = useCallback(async (companyId) => {
    try {
      const res = await getEmployeesRequest(companyId);
      setEmployees(res.data.users);
    } catch (error) {
      console.error(error);
    }
  }, [getEmployeesRequest, setEmployees]);

  const closeAndResetModal = useCallback(() => {
    closeModal();
    setModalContent(null);
  }, [closeModal, setModalContent]);

  const declineSelectedEmployee = useCallback(async (employee) => {
    try {
      await removeEmployee(user.companyId, { userId: employee.userId });
      toast.success(t(`${tKey}delete.success`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } catch (error) {
      console.error(error);
      toast.error(t(`${tKey}delete.error`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } finally {
      closeAndResetModal();
      await fetchCompanyEmployees(user.companyId);
    }
  }, [removeEmployee, user.companyId, t, tKey, closeAndResetModal, fetchCompanyEmployees]);

  const acceptSelectedEmployee = useCallback(async (employee) => {
    try {
      await acceptEmployee(user.companyId, { userId: employee.userId });
      toast.success(t(`${tKey}accept.success`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } catch (error) {
      console.error(error);
      toast.error(t(`${tKey}accept.error`, { fullname: `${employee.firstname} ${employee.lastname}` }));
    } finally {
      closeAndResetModal();
      await fetchCompanyEmployees(user.companyId);
    }
  }, [acceptEmployee, user.companyId, t, tKey, closeAndResetModal, fetchCompanyEmployees]);

  const handleDetail = useCallback(async (row) => {
    try {
      const response = await getEmployeeDetail(row.original.userId);
      const employeeData = response.data;
      setModalContent(
        <div className="flex align-center gap-3">
          <div>
            <UserAvatar src={employeeData.imagePath} size="100" />
          </div>
          <div className="flex-grow">
            <h5 className="my-0">
              {employeeData.firstname} {employeeData.lastname}
            </h5>
            <div>
              <p className="my-1">{employeeData.email}</p>
              <p className="my-1">{employeeData.phone}</p>
              <p className="my-1">{employeeData.occupiedFunction}</p>
            </div>
          </div>
        </div>
      );
      openModal();
    } catch (error) {
      console.error(error);
    }
  }, [getEmployeeDetail, setModalContent, openModal]);

  const handleDecline = useCallback((employee) => () => {
    setModalContent(<>
      <p>{t(`${tKey}delete.text`, { fullname: `${employee.firstname} ${employee.lastname}` })}</p>
      <div className='modal-footer'>
        <Button variant='secondary' onClick={closeAndResetModal}>{t('utils.common.close')}</Button>
        <Button variant='danger' onClick={() => declineSelectedEmployee(employee)}>{t('utils.common.confirmDecline')}</Button>
      </div>
    </>);
    openModal();
  }, [t, tKey, setModalContent, openModal, closeAndResetModal, declineSelectedEmployee]);

  const getEmployeeActions = useCallback((employee) => {
    const actions = [
      <Button variant='primary' icon onClick={() => handleDetail(employee)}>
        <FontAwesomeIcon icon={faEye} size='sm' />
      </Button>,
    ]
    if (!employee.userId === user.userId)
      actions.unshift(
        <Button variant='danger' icon onClick={(e) => {
          e.stopPropagation();
          handleDecline(employee)();
        }}>
          <FontAwesomeIcon icon={faTrash} size='sm' />
        </Button>
      );
    if (!employee.usercompany.isConfirmed)
      actions.unshift(
        <Button variant='success' icon onClick={(e) => { 
          e.stopPropagation();
          acceptSelectedEmployee(employee);
        }}>
          <FontAwesomeIcon icon={faCheck} size='sm' />
        </Button>
      );
    return actions;
  }, [user.userId, handleDetail, handleDecline, acceptSelectedEmployee]);

  const columns = useMemo(() => [
    {
      accessor: 'userId',
      disableSortBy: true,
      Cell: '',
    },
    {
      accessor: 'imagePath',
      Cell: ({ row }) => {
        return <UserAvatar src={row.original.imagePath} size='50' />;
      },
      
    },
    {
      accessor: 'fullname',
      Cell: ({ row }) => `${row.original.firstname} ${row.original.lastname}`,
    },
    {
      id: 'actions',
      Cell: ({ row }) => (<div className='flex gap-1'>
        { getEmployeeActions(row.original) }
      </div>),
      disableSortBy: true,
    },
  ], [getEmployeeActions]);

  const filterEmployees = (list, query) => {
    if (query)
      return list.filter((employee) => {
        return employee.firstname.toLowerCase().includes(query.toLowerCase()) ||
          employee.lastname.toLowerCase().includes(query.toLowerCase());
      });
    return list;
  };

  useEffect(() => {
    fetchCompanyEmployees(user.companyId);
  }, [user.companyId, fetchCompanyEmployees]);

  useEffect(() => {
    setFilteredPendingEmployees(filterEmployees(employees.filter((employee) => !employee.usercompany.isConfirmed), query));
    setFilteredEmployees(filterEmployees(employees.filter((employee) => employee.usercompany.isConfirmed), query));
  }, [query, employees]);

  if (getEmployeesError) return <div>Error: {getEmployeesError}</div>;
  if (getEmployeesLoading) return <Spinner />;

  return (<>
    <div className='dashboard-content w-100'>
      <DecoratedInput className='bg-white pl-2 dashboard-search-bar w-100' input={{ id: 'searchEmployee', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: employees ? employees.length : 0 }) }}
        hasResearchButton
        onResearchButtonClick={ (query) => { setQuery(query) } }
      />
      <div className='flex gap-1 mt-2'>
        <div className='w-50 bg-white p-3 rounded'>
          <p className='m-0 p1'>{t(`${tKey}tableHeader`)}</p>
          {filteredEmployees?.length === 0 ? <NoData />
            : <Table noHeader rowClassName='p-4' columns={columns} data={filteredEmployees} onClickRow={handleDetail} />}    
        </div>    
        <div className='w-50 bg-white p-3 rounded'>
          <p className='m-0 p1'>{t(`${tKey}pendingTableHeader`)}</p>
            {filteredPendingEmployees?.length === 0 ? <NoData />
              : <Table noHeader rowClassName='p-4' columns={columns} data={filteredPendingEmployees} onClickRow={handleDetail} />}    
        </div>
      </div>
    </div>
    <Modal isOpen={isModalOpen} onClose={closeAndResetModal} noFooter>
      {modalContent}
    </Modal>
  </>);
};

export default CompanyEmployees;