import React from 'react';
import { useTranslation } from 'react-i18next';

function ConfirmRegister({ role, lastname, firstname, entityName }) {
  const { t } = useTranslation();
  return (
    <div className='register-step'>
      <div className='step-header'>
        <h2>{t('register.stepConfirm.title')}</h2>
      </div>
      <div className='step-body'>
          <p dangerouslySetInnerHTML={{ __html: t(`register.stepConfirm.${role}Text`, { lastname, firstname, entityName }) }} />
      </div>
    </div>
  );
}

export default ConfirmRegister;