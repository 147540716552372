import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { Tooltip } from 'react-tooltip';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical, faEllipsisVertical, faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useApi from '../../../hooks/useApi';
import useModal from '../../../hooks/useModal';
import Button from '../../buttons/Button';
import DecoratedInput from '../../inputs/DecoratedInput';
import NoData from '../../errors/NoData';
import Spinner from '../../utils/Spinner';
import Table from '../../table/Table';
import DownloadTable from '../../table/DownloadTable';
import DropdownButton from '../../buttons/DropdownButton';
import Modal from '../../utils/Modal';
import { getMenus, deleteMenu, duplicateMenu, updateMenusOrder } from '../../../services/menuService';
import { usePageTitle } from '../../../hooks/useMeta';

export const RestaurantMenus = ({ tKey = 'dashboard.menus.' }) => {
  usePageTitle('restaurantMenus');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isModalOpen, openModal, closeModal } = useModal();
  const {
    error: getMenusError,
    loading: getMenusLoading,
    request: getMenusRequest,
  } = useApi(getMenus);
  const {
    request: deleteMenuRequest,
  } = useApi(deleteMenu);
  const {
    request: duplicateMenuRequest,
  } = useApi(duplicateMenu);
  const [query, setQuery] = useState('');
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [menus, setMenus] = useState([]);
  const [filteredMenus, setFilteredMenus] = useState([]);
  const [modalContent, setModalContent] = useState(null);
  const restaurantId = useSelector((state) => state.user.restaurantId);

  const fetchRestaurantMenus = useCallback(async (restaurantId) => {
    await getMenusRequest(restaurantId)
      .then((response) => {
        setMenus(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [getMenusRequest, setMenus]);

  const handleDetail = useCallback((row) => {
    navigate(`${row.original.menuId}`);
  }, [navigate]);

  const handleDuplicate = useCallback(async (menu) => {
    await duplicateMenuRequest(menu)
      .then(() => {
        fetchRestaurantMenus(restaurantId);
        toast.success(t(`${tKey}duplicate.success`, { menuName: menu.name }));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}duplicate.error`, { menuName: menu.name }));
      })
  }, [duplicateMenuRequest, fetchRestaurantMenus, restaurantId, t, tKey]);

  const handleDelete = useCallback((menu) => {
    setSelectedMenu(menu);
    setModalContent(<>
      <h5 className='mt-0'>{t(`${tKey}delete.title`)}</h5>
      <p>{t(`${tKey}delete.text`, { menuName: menu.name })}</p>
    </>);
    openModal();
  }, [setSelectedMenu, setModalContent, t, tKey, openModal]);

  const columns = useMemo(() => [
    {
      Header: (<div>
        <FontAwesomeIcon
          icon={faInfoCircle}
          data-tooltip-id="infoTip"
          data-tooltip-html={t(`${tKey}order.info`)}
        />
        <Tooltip id="infoTip" place="bottom" effect="solid" />
      </div>),
      accessor: 'order',
      isDragHandle: true,
      Cell: () => <FontAwesomeIcon color='black' icon={faGripDotsVertical} />,      
      disableSortBy: true,
    },
    {
      Header: t(`${tKey}title`),
      accessor: 'name',
    },
    {
      Header: t(`${tKey}price`),
      accessor: 'price',
      Cell: ({ row }) => {
        const { price, discount } = row.original;
        return discount 
          ? <>
            <div>{(price - price * discount / 100).toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}</div>
            <div className='strikethrough text-light p4 mt-1'>{price.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })}</div>
          </>
          : price.toLocaleString('de-CH', { style: 'currency', currency: 'CHF' })
      },
    },
    {
      Header: t(`${tKey}status.header`),
      Cell: ({ row }) => (
        <div className={`rounded text-white py-1 px-3 text-center fit-content ${!row.original.isPublished ? 'bg-dark' : row.original.isActive ? 'bg-success' : 'bg-danger'}`}>{t(`${tKey}status.${!row.original.isPublished ? 'unpublished' : row.original.isActive ? 'active' : 'inactive'}`)}</div> 
      ),
      disableSortBy: true,
    },
    {
      Header: '',
      id: 'actions',
      Cell: ({ row }) => (
        <DropdownButton
          actions={[
            { label: t(`${tKey}actions.edit`), onClick: () => handleDetail(row) },
            { label: t(`${tKey}actions.duplicate`), onClick: () => handleDuplicate(row.original) },
            { label: t(`${tKey}actions.delete`), onClick: () => handleDelete(row.original) },
          ]}
        >
          <FontAwesomeIcon color='grey' icon={faEllipsisVertical} />
        </DropdownButton>
      ),
      disableSortBy: true,
    },
  ], [handleDelete, handleDetail, handleDuplicate, t, tKey]);

  const statusOptions = useMemo(() => [
    { value: 'active', label: t(`${tKey}status.active`) },
    { value: 'inactive', label: t(`${tKey}status.inactive`) },
    { value: 'unpublished', label: t(`${tKey}status.unpublished`) },
  ], [t, tKey]);

  const goToAdd = () => {
    navigate('add');
  };

  const handleOrderChange = async (newOrder) => {
    await updateMenusOrder(restaurantId, newOrder.map(menu => menu.menuId))
      .then((res) => {
        setMenus(res.data);
        toast.success(t(`${tKey}order.success`));
      })
      .catch((err) => {
        console.error(err);
        toast.error(t(`${tKey}order.error`));
      });
  };

  const deleteSelectedMenu = async () => {
    await deleteMenuRequest(selectedMenu.menuId)
    .then((res) => {
      if (res.status === 200) {
        setMenus(menus.filter(m => m.menuId !== selectedMenu.menuId));
        toast.success(t(`${tKey}delete.success`, { menuName: selectedMenu.name }));
      };
    })
    .catch((err) => {
      console.error(err);
      toast.error(t(`${tKey}delete.error`, { menuName: selectedMenu.name }));
    })
    .finally(async () => {
      setModalContent(null);
      setSelectedMenu(null);
      closeModal();
      await fetchRestaurantMenus(restaurantId);
    });
  };

  const filterMenus = useCallback((query) => {
    if(!menus) return [];
    let filteredMenus = [...menus];
    if (selectedStatus)
      switch(selectedStatus.value) {
        case 'active':
          filteredMenus = filteredMenus.filter(m => m.isPublished && m.isActive);
          break;
        case 'inactive':
          filteredMenus = filteredMenus.filter(m => m.isPublished && !m.isActive);
          break;
        case 'unpublished':
          filteredMenus = filteredMenus.filter(m => !m.isPublished);
          break;
        default:
          break;
      }
    if (query) filteredMenus = filteredMenus.filter(m => m.name.toLowerCase().includes(query.toLowerCase()));
    return filteredMenus;
  }, [menus, selectedStatus]);

  useEffect(() => {
    fetchRestaurantMenus(restaurantId);
  }, [restaurantId, fetchRestaurantMenus]);

  useEffect(() => {
    setFilteredMenus(filterMenus(query));
  }, [query, menus, selectedStatus, filterMenus]);

  if (getMenusError) return <div>Error: {getMenusError}</div>;
  if (getMenusLoading) return <Spinner />;

  return (
    <div className='dashboard-content'>
      <div className='flex gap-1'>
        <Button variant='primary' size='sm' onClick={goToAdd}>
          <FontAwesomeIcon icon={faPlus} />
            <span className='ml-2'>{t(`${tKey}addMenu`)}</span>
        </Button>
        <div className='ml-auto flex gap-1 w-80'>
          <DecoratedInput className='bg-white dashboard-search-bar w-100' input={{ id: 'searchMenu', class: 'p4', value: '', placeholder: t(`${tKey}searchPlaceholder`, { count: menus ? menus.length : 0 }) }}
            hasResearchButton
            onResearchButtonClick={ (query) => { setQuery(query) } }
          />          
          <Select
            className='react-custom-select dashboard-select'
            classNamePrefix="react-select"
            placeholder={t(`${tKey}status.header`)}
            options={statusOptions}
            value={selectedStatus}
            isClearable
            isSearchable={false}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            onChange={(value) => setSelectedStatus(value)}
          />
          <DownloadTable data={filteredMenus} fileName='menus' fileTypes={['csv', 'xlsx']} />
        </div>
      </div>
      <div className='mt-4 table-container'>
        {filteredMenus?.length === 0 ? <NoData />
          : <Table columns={columns} data={filteredMenus} isDroppable={!query && !selectedStatus} onOrderChange={handleOrderChange} onClickRow={handleDetail} />}          
      </div>
      <Modal isOpen={isModalOpen} onClose={closeModal} onOK={deleteSelectedMenu}>
        {modalContent}
      </Modal>
    </div>
  );
};

export default RestaurantMenus;