import React, { useEffect, useState } from 'react';
import { usePageTitle } from '../../../hooks/useMeta';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCompanyPartners } from '../../../services/companyService';
import { getCompanyUsers } from '../../../services/userService';
import Tag from '../../utils/Tag';
import Button from '../../buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightLong } from '@fortawesome/pro-light-svg-icons';
import { faClipboard } from '@fortawesome/pro-regular-svg-icons';
import { copyToClipboard } from '../../../utils/download';

export const CompanyOverview = ({ tKey = 'dashboard.company.' }) => {
  usePageTitle('companyOverview');
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [partners, setPartners] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [pendingEmployees, setPendingEmployees] = useState([]);
  const [validationCode, setValidationCode] = useState('');  
  const companyId = useSelector((state) => state.user.companyId);
  
  const fetchPartners = async (companyId) => {
    await getCompanyPartners(companyId)
      .then((response) => {        
        setPartners(response.data);        
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchEmployees = async (companyId) => {
    await getCompanyUsers(companyId)
      .then((response) => {
        setValidationCode(response.data.validationCode);
        setEmployees(response.data.users.filter((u) => u.usercompany.isConfirmed));
        setPendingEmployees(response.data.users.filter((u) => !u.usercompany.isConfirmed));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchPartners(companyId);
    fetchEmployees(companyId);
  }, [companyId]);

  return (
    <div className='dashboard-content flex flex-wrap'>
      <div className='w-70 h-40'>
        <div className='bg-white h-100 rounded mr-3 p-3 flex column'>
          <div className='flex justify-between p1'>
            <span>{ `${t(`${tKey}partners`)}` }</span>
            <span>{ partners.length }</span>
          </div>
          <div>
            {partners.length ?
              partners.map((partner, index) => (<div key={index} className='flex gap-4 my-4'>
                <Tag variant='dark' text={ index + 1 } />
                <span>{ partner.restaurant.name }</span>
                <span>{ t(`utils.offerTypes.${partner.offertype.label}`) }</span>
              </div>)).slice(0, 5) :
              <div className='self-align-center italic'>{ t('utils.errors.noData.title') }</div>
            }
          </div>
          <div className='mt-auto ml-auto'>
            <Button className='text-light' link onClick={() => navigate('partners')}>
              { t(`${tKey}viewAllPartners`) }
              <FontAwesomeIcon className='ml-2' icon={faArrowRightLong} />
            </Button>
          </div>
        </div>
      </div>
      <div className='w-30 h-40 flex column bg-white rounded p-3'>
        <div className='flex justify-between p1'>
          <span>{ `${t(`${tKey}validationCode`)}` }</span>
          <Button variant='primary' icon onClick={() => copyToClipboard(validationCode, t('utils.common.copiedToClipboard'))}>            
            <FontAwesomeIcon icon={faClipboard} />
          </Button>
        </div>
        <div className='flex justify-between m-4'>
          { validationCode.split('').map((char, index) => (
            <span key={index} className='p1 bold bg-pastel-light rounded p-2'>{ char }</span>
          ))}
        </div>
        <div>
          { t(`${tKey}validationCodeDescription`) }
        </div>
      </div>
      <div className='w-30 h-50 bg-white rounded p-3 flex column'>
        <div className='flex justify-between p1'>
          <span>{ `${t(`${tKey}employees`)}` }</span>
          <span>{ employees.length }</span>
        </div>
        <div className='flex justify-between h-100'>
          { employees.length ?
            employees.map((employee, index) => (
              <div key={index} className='flex gap-4 my-4'>
                <Tag variant='dark' text={ index + 1 } />
                <span>{ `${employee.firstname} ${employee.lastname}` }</span>
                <span>{ employee.email }</span>
              </div>
            )) :
            <div className='mt-4 self-align-center italic'>{ t('utils.errors.noData.title') }</div>
          }
        </div>
        <div className='mt-auto ml-auto'>
          <Button className='text-light' link onClick={() => navigate('employees')}>
            { t(`${tKey}viewAllEmployees`) }
            <FontAwesomeIcon className='ml-2' icon={faArrowRightLong} />
          </Button>
        </div>
      </div>
      <div className='w-30 h-50 bg-white rounded ml-3 p-3 flex column'>
        <div className='flex justify-between p1'>
          <span>{ `${t(`${tKey}pendingEmployees`)}` }</span>
          <span>{ pendingEmployees.length }</span>
        </div>
        <div className='flex justify-between h-100'>
          { pendingEmployees.length ?
            pendingEmployees.map((employee, index) => (
              <div key={index} className='flex gap-4 my-4'>
                <Tag variant='dark' text={ index + 1 } />
                <span>{ `${employee.firstname} ${employee.lastname}` }</span>
                <span>{ employee.email }</span>
              </div>
            )) :
            <div className='self-align-center italic'>{ t('utils.errors.noData.title') }</div>
          }
        </div>
        <div className='mt-auto ml-auto'>
          <Button className='text-light' link onClick={() => navigate('employees')}>
            { t(`${tKey}viewAllEmployees`) }
            <FontAwesomeIcon className='ml-2' icon={faArrowRightLong} />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CompanyOverview;