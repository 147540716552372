import React from "react";

export const AdminUsers = () => {
  return (
    <div>
      Admin users
    </div>
  );
};

export default AdminUsers;