import React, { useState, useEffect } from 'react';
import Button from '../buttons/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { useTranslation } from 'react-i18next';
import FlipMove from 'react-flip-move';

const PromotionForm = ({ data = null, onPromotionsChange, tKey = 'dashboard.settings.catering.promotions.' }) => {
    const { t } = useTranslation();
    const [promotions, setPromotions] = useState([{ percentage: null, thresholdAmount: null }]);

    const handleChangePercentage = (index, value) => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions];
            newPromotions[index].percentage = Number(value);
            return newPromotions;
        });
    };

    const handleChangeThresholdAmount = (index, value) => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions];
            newPromotions[index].thresholdAmount = value;
            return newPromotions;
        });
    };

    const handleBlurThresholdAmount = (index, value) => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions];
            newPromotions[index].thresholdAmount = Number(value);
            for (let i = 1; i < newPromotions.length; i++) {
                if (newPromotions[i].percentage > newPromotions[i - 1].percentage && 
                    newPromotions[i].thresholdAmount <= newPromotions[i - 1].thresholdAmount) {
                    newPromotions[i].thresholdAmount = newPromotions[i - 1].thresholdAmount + 1;
                }
                else if (newPromotions[i].percentage < newPromotions[i - 1].percentage && 
                         newPromotions[i].thresholdAmount >= newPromotions[i - 1].thresholdAmount) {
                    newPromotions[i].thresholdAmount = newPromotions[i - 1].thresholdAmount - 1;
                }
            }
            return newPromotions;
        });
    };

    const handleAddPromotion = () => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions, { percentage: null, thresholdAmount: null }];
            newPromotions.sort((a, b) => (a.percentage === null) - (b.percentage === null) || a.percentage - b.percentage);
            return newPromotions;
        });
    };

    const handleRemovePromotion = (index) => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions];
            newPromotions.splice(index, 1);
            return newPromotions;
        });
    };

    const handleBlurPercent = (index, value) => {
        setPromotions(prevPromotions => {
            const newPromotions = [...prevPromotions];
            newPromotions[index].percentage = Number(value);
            newPromotions.sort((a, b) => (a.percentage === null) - (b.percentage === null) || a.percentage - b.percentage);
            return newPromotions;
        });
    };

    useEffect(() => {
        if (data && data.length > 0) setPromotions(data);
    }, [data]);

    useEffect(() => {
        const filteredPromotions = promotions.filter(promotion => promotion.percentage && promotion.thresholdAmount);
        onPromotionsChange(filteredPromotions);
    }, [promotions, onPromotionsChange]);

    return (<>        
        <label>{t(`${tKey}label`)}</label>
        <FlipMove duration={300}>
            {promotions
                .map((promotion, index) => ({ index, promotion }))
                .sort((a, b) => a.percentage - b.percentage)
                .map(({ index }) => {
                    return (
                        <div className='flex gap-1 mt-2 align-center' key={index}>
                            <div className='flex-grow flex gap-1'>
                                <div className='w-50 input-percent'>
                                    <input className='bg-pastel-light'
                                        type="number"
                                        value={promotions[index].percentage || ''}
                                        placeholder={t(`${tKey}percentage.placeholder`)}
                                        onChange={(e) => handleChangePercentage(index, e.target.value)}
                                        onBlur={(e) => handleBlurPercent(index, e.target.value)}
                                    />
                                </div>
                                <div className='w-50 input-chf'>
                                    <input className='bg-pastel-light'
                                        type="number"
                                        value={promotions[index].thresholdAmount}
                                        placeholder={t(`${tKey}thresholdAmount.placeholder`)}
                                        onChange={(e) => handleChangeThresholdAmount(index, e.target.value)}
                                        onBlur={(e) => handleBlurThresholdAmount(index, e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='w-10'>
                                {promotions.length > 1 &&
                                    <Button variant='danger' size='xs' rounded 
                                        onClick={() => handleRemovePromotion(index)}>
                                        <FontAwesomeIcon icon={faMinus} />  
                                    </Button>
                                }
                            </div>
                        </div>
                    );
            })}
        </FlipMove>
        {promotions.length < 3 &&
            <Button className='mt-2' variant='secondary' size='xs' rounded onClick={() => handleAddPromotion()}>
                <FontAwesomeIcon icon={faPlus} />  
            </Button>
        }
    </>);
};

export default PromotionForm;