import { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setCreationDate } from '../redux/slices/restaurantsSlice';
import { getRestaurantCreatedAt } from '../services/restaurantService';
import useApi from './useApi';

export function useCreatedDate(restaurantId) {    
    const {
        request: getCreationDateRequest,
    } = useApi(getRestaurantCreatedAt);
    const dispatch = useDispatch();
    const restaurantCreatedAt = useSelector((state) => state.restaurant.restaurantCreatedAt);
    
    const fetchRestaurantCreatedAt = useCallback(async () => {
        await getCreationDateRequest(restaurantId)
        .then((response) => {
            dispatch(setCreationDate(response.data));
        });
    }, [getCreationDateRequest, dispatch, restaurantId]);

    useEffect(() => {
        if(!restaurantCreatedAt) fetchRestaurantCreatedAt();
    }, [fetchRestaurantCreatedAt, restaurantCreatedAt]);

    return { restaurantCreatedAt };
};