import { deleteRequest, getRequest, postRequest, putRequest } from '../api/methodsCfg';

export const getUser = async (userId) => {
    const response = await getRequest(`/user/${userId}`);
    return response;
};

export const getRestaurantUserDetail = async (userId) => {
    const response = await getRequest(`/user/details/${userId}`);
    return response;
};

export const getCompanyUserDetail = async (userId) => {
    const response = await getRequest(`/user/details/${userId}`);
    return response;
};

export const getRestaurantUsers = async (restaurantId) => {
    return await getRequest(`/restaurant/employees/${restaurantId}`);
};

export const getCompanyUsers = async (companyId) => {
    return await getRequest(`/company/companiesusers/${companyId}`);
};

export const createNewRestaurantEmployee = async (payload, restaurantId) => {
    const response = await postRequest(`/restaurant/addEmployee/${restaurantId}`, payload);
    return response;
};

export const updateRestaurantEmployee = async (userId, payload) => {
    const response = await putRequest(`/user/${userId}/${payload.lastname}/${payload.firstname}`, payload, true);
    return response;
};

export const updateCompanyEmployee = async (userId, payload) => {
    const response = await putRequest(`/user/${userId}/${payload.lastname}/${payload.firstname}`, payload, true);
    return response;
};

export const closeRestaurantEmployeeAccount = async (userId) => {
    const response = await putRequest(`/user/closeAccount/${userId}`);  
    return response;
};

export const closeRestaurantManagerAccount = async (restaurantId, payload) => {
    const response = await deleteRequest(`/restaurant/manager/${restaurantId}`, payload);
    return response;
};

export const closeCompanyManagerAccount = async (companyId, payload) => {
    const response = await deleteRequest(`/company/manager/${companyId}`, payload);
    return response;
};

export const acceptCompanyEmployee = async (companyId, payload) => {
    const response = await putRequest(`/company/acceptEmployee/${companyId}`, payload);
    return response;
};

export const removeCompanyEmployee = async (companyId, payload) => {
    const response = await putRequest(`/company/removeEmployee/${companyId}`, payload);
    return response;
};

export const getNotifications = async (userId) => {
    const response = await getRequest(`/notifications/${userId}`);
    return response;
};

export const putReadNotification = async (notificationId) => {
    const response = await putRequest(`/notifications/read/${notificationId}`);
    return response;
};

export const putUserLanguage = async (userId, lang) => {
    const response = await putRequest(`/user/lang/${userId}`, { lang });
    return response;
}